import { useEffect } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { useUserStore } from "../context/user";
// import { useUserStore } from "../context/user";

const ProtectedRoute = ({ component: Comp, loggedInStatus, path, ...rest }) => {
  const { setRedirectPath } = useUserStore();
  const location = useLocation();
  useEffect(() => {
    setRedirectPath({ redirectPath: location.pathname });
  }, [location.pathname, setRedirectPath]);

  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        return loggedInStatus ? (
          <Comp {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: {
                prevLocation: path,
                error: "You need to login first!",
              },
            }}
          />
        );
      }}
    />
  );
};

export default ProtectedRoute;
