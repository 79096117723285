import {
  Button,
  ClickAwayListener,
  Grid,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { CloudUpload, DeleteRounded, Info } from "@material-ui/icons";
import { useEffect, useRef, useState } from "react";

export const MultiUpload = (props) => {
//   console.log(props.meta);
  const [isError, setIsError] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const hiddenInput = useRef();
  useEffect(() => {
    setIsError(props?.meta?.error && props?.meta?.touched);
  }, [props?.meta?.error, props?.meta?.touched]);
  const handleUploadChange = (event) => {
    console.log(event.target.files[0])
    props.form.setFieldValue("files", [
      event.target.files[0],
      ...props.field.value,
    ]);
  };
  const handleDeleteUpload = (e) => {
    var arr = props.field.value.filter((item) => {
      return item.name !== e.currentTarget.name;
    });
    props.form.setFieldValue("files", arr);
  };
  return (
    <Grid>
      <Grid container alignItems="center">
        <input
          style={{ display: "none" }}
          ref={hiddenInput}
          type="file"
          onChange={handleUploadChange}
          {...props}
        />
        <Button
          variant="contained"
          component="span"
          startIcon={<CloudUpload />}
          style={{
            textTransform: "none",
          }}
          onClick={() => {
            hiddenInput.current.click();
            props.form.setFieldTouched("files");
          }}
          disabled={isError}
        >
          {props.teksti}
        </Button>
        <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
          <Tooltip
            open={openTooltip}
            disableFocusListener
            disableHoverListener
            placement="top"
            title={
              "Madhesia e dokumentave nuk duhet te kaloje 15MB. \n Formati i skedareve duhet te jete i tipit doc / docx / pdf / jpg / jpeg"
            }
          >
            <Info
              onClick={() => setOpenTooltip(true)}
              style={{ marginLeft: "10px", width: "20px", height: "20px" }}
            />
          </Tooltip>
        </ClickAwayListener>
      </Grid>
      {isError && (
        <p
          style={{
            fontSize: "0.75rem",
            color: "#f44336",
            lineHeight: "1.66",
            letterSpacing: "0.03333em",
          }}
        >
          {props?.meta?.error}
        </p>
      )}
      <Grid container style={{ marginTop: "2vh", marginBottom: "2vh" }}>
        {props.field.value.map((item, i) => {
          const lastIndexOfDot = item.name.lastIndexOf(".");
          const fullName = item.name.slice(0, lastIndexOfDot - 1);
          const extenstion = item.name.slice(lastIndexOfDot);
          const shortName = `${fullName.substring(0, 15)}..${extenstion}`;
          const isFirst = i === 0;
          return (
            <div
              style={{
                marginRight: "15px",
                border:
                  isError && isFirst
                    ? "1px dashed #f44336"
                    : "1px dashed #b7b7b7",
                paddingLeft: "8px",
                borderRadius: "15px",
                marginBottom: "15px",
              }}
            >
              <span
                style={{
                  color: isError && isFirst ? "#f44336" : "#545454",
                  marginRight: "5px",
                }}
              >
                {shortName}
              </span>

              <IconButton
                name={item.name}
                onClick={handleDeleteUpload}
                style={{ marginRight: "5px" }}
              >
                <DeleteRounded
                  style={{ fill: isError && isFirst ? "#f44336" : "#000000de" }}
                />
              </IconButton>
            </div>
          );
        })}
      </Grid>
    </Grid>
  );
};
