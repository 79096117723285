import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import GA4React from "ga-4-react";

const ga4react = new GA4React("G-5PN0X9DF6J");

const useGaTracker = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    (async () => {
      if (!window.location.href.includes("localhost")) {
        try {
          await ga4react.initialize();
          setInitialized(true);
        } catch (e) {
          console.log("cloud not initialize google analytics");
          console.log("with err: ", { e });
          setInitialized(false);
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (initialized) {
      const path = location.pathname + location.search;
      ga4react.pageview(path);
    }
  }, [initialized, location]);
};

export default useGaTracker;
