import { Route, Switch } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import { useUserStore } from "./context/user";
import { Paneli } from "./pages/Paneli";
import { PublicPage } from "./pages/PublicPage";
import useGaTracker from "./utils/useGaTracker";

export const Routing = () => {
  // google analytics
  useGaTracker();
  const { loggedInStatus } = useUserStore();
  return (
    <Switch>
      <Route exact path="/" component={PublicPage} />
      <ProtectedRoute
        path="/kontributet*"
        loggedInStatus={loggedInStatus}
        component={Paneli}
      />
      {/* //TODO: maybe a catch all route would be nice :)  */}
    </Switch>
  );
};
