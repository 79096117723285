import { CircularProgress, Grid, useMediaQuery } from "@material-ui/core";
import { Error, Warning } from "@material-ui/icons";
import { gql } from "graphql-request";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { FormaAplikimit } from "../components/FormaAplikimit";
import { useUserStore } from "../context/user";
import { useAuthorizedGraphqlClient } from "../utils/useAuthorizedGraphqlClient";
import "./FormulariAplikimitPage.scss";

export const FormulariAplikimitPage = () => {
  const client = useAuthorizedGraphqlClient();
  const {
    data: kontributetCount,
    isLoading: isLoadingKontributeCount,
    isError: isErrorKontributeCount,
  } = useGetKontributetCount({ client });

  const matches = useMediaQuery("(max-width:600px)");

  const { emri, mbiemri } = useUserStore();

  return (
    <div className="forma-aplikimit-panel">
      {isLoadingKontributeCount ? (
        <Grid container direction="column" alignItems="center">
          <CircularProgress size="3rem" color="secondary" />
          <p>Duke marrë të dhënat</p>
        </Grid>
      ) : isErrorKontributeCount ? (
        <Grid container direction="column" alignItems="center">
          <Error fontSize="large" color="secondary" />
          <p>Një gabim ka ndodhur, provoni përsëri.</p>
        </Grid>
      ) : !kontributetCount ? (
        <div style={{ backgroundColor: "white", padding: "5vh 35px" }}>
          <p className="forma-aplikimit-panel-tekst-kryesor">
            Formulari i aplikimit
          </p>
          <p className="forma-aplikimit-panel-emri">
            {emri} {mbiemri}
          </p>
          <FormaAplikimit />
        </div>
      ) : (
        <div style={{ maxWidth: matches ? "80vw" : "50vw", margin: "0 auto" }}>
          <Grid
            alignItems="center"
            container
            style={{
              flexDirection: "column",
              border: "1px gray dashed",
              borderRadius: "15px",
              padding: "5vw",
              color: "#545454",
            }}
          >
            <Warning
              style={{
                marginBottom: "2vw",
                fontSize: matches ? "12vw" : "4vw",
                color: "#CF0429",
              }}
            />
            <p
              style={{
                textAlign: "center",
                fontSize: matches ? "18px" : "24px",
              }}
            >
              Ju keni një kontribut të regjistruar. Për ta parë atë kliko{" "}
              <Link to="/kontributet" style={{ color: "#CF0429" }}>
                këtu
              </Link>
            </p>
          </Grid>
        </div>
      )}
    </div>
  );
};

const useGetKontributetCount = ({ client }) => {
  return useQuery("getKontributetCount", async () => {
    const data = client.request(
      gql`
        query {
          kontributet_aggregate {
            aggregate {
              count
            }
          }
        }
      `
    );

    const {
      kontributet_aggregate: {
        aggregate: { count },
      },
    } = await data;

    return count;
  });
};
