import "./PublicPage.scss";
import fotoKryesore from "../assets/kryesoreFullKompresuar.jpg";
import { Button } from "@material-ui/core";
import { useState } from "react";
import { LoginComponent } from "../components/LoginComponent";
import { RegisterComponent } from "../components/RegisterComponent";
import { useRef } from "react";
import { ConfirmRegisterPopUp } from "../components/ConfirmRegisterPopup";
import { ResetPasswordComponent } from "../components/ResetPasswordComponent";

export const PublicPage = () => {
  // const queryParams = useQueryParams();
  // const history = useHistory();
  // const { logIn } = useUserStore();

  // useEffect(() => {
  //   const idToken = queryParams.get("id_token");

  //   if (idToken) {
  //     const endpoint = "idp";
  //     logInnnnn({ idToken, logIn, history, endpoint });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const seksioniIdentifikohu = useRef(null);
  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(true);
  const [showResetPassword, setShowResetPassword] = useState(false);

  const handleLogin = () => {
    setShowRegister(false);
    setShowResetPassword(false);
    setShowLogin(true);
    seksioniIdentifikohu.current.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  };

  const handleRegister = () => {
    setShowLogin(false);
    setShowResetPassword(false);
    setShowRegister(true);
    seksioniIdentifikohu.current.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  };

  const handleReset = () => {
    setShowRegister(false);
    setShowLogin(false);
    setShowResetPassword(true);
  };

  const [confirmRegisterPopupStatus, setConfirmRegisterPopupStatus] =
    useState(false);
  const [email, setEmail] = useState("");

  return (
    <>
      <div className="butonat">
        <Button
          disableElevation
          disableRipple
          variant="contained"
          size="small"
          style={{
            backgroundColor: "#CF0429",
            color: "white",
            textTransform: "none",
            borderRadius: "4px",
          }}
          onClick={handleLogin}
        >
          Identifikohu
        </Button>
        <Button
          disableElevation
          disableRipple
          size="small"
          style={{
            textTransform: "none",
            marginLeft: "2vw",
            marginRight: "3vw",
            border: "2px solid #CF0429",
            borderRadius: "4px",
            maxHeight: "30.62px",
            color: "#CF0429",
          }}
          onClick={handleRegister}
        >
          Regjistrohu
        </Button>
      </div>
      <div className="formular">
        <img alt="foto-kryesore" src={fotoKryesore} />
        <div className="formular-forma">
          <p className="formular-forma-tekst-kryesor">
            Kjo platformë do të jetë në dispozicion të të gjithë atyre, që kanë
            përfunduar studimet e nivelit të parë dhe të dytë, bachelor dhe
            master, me rezultate të larta, jashtë shtetit apo në Shqipëri. Për
            të gjithë ata, të cilët kanë dëshirë të kontaktojnë Kryeministrin
            apo anëtarët e tjerë të kabinetit qeveritar për të kontribuar në
            administratën publike, si: Nëpunës; Nëpunës digjital; Ekspert i
            jashtëm për projekte ekzistuese apo ide të reja. Është radha juaj të
            shprehni interesin dhe gadishmërinë për t’u përfshirë drejtpërdrejt
            në punët e mëdha të mandatit të tretë, të qeverisë Rama.
          </p>
          <div ref={seksioniIdentifikohu} className="forma-aplikimit">
            <p className="forma-aplikimit-tekst-kryesor">
              Formulari i aplikimit
            </p>
            <LoginComponent
              showLogin={showLogin}
              setConfirmRegisterPopupStatus={setConfirmRegisterPopupStatus}
              setEmail={setEmail}
              handleReset={handleReset}
            />
            <RegisterComponent
              showRegister={showRegister}
              setConfirmRegisterPopupStatus={setConfirmRegisterPopupStatus}
              setEmail={setEmail}
            />
            <ResetPasswordComponent showResetPassword={showResetPassword} />
          </div>
          <ConfirmRegisterPopUp
            confirmRegisterPopupStatus={confirmRegisterPopupStatus}
            setConfirmRegisterPopupStatus={setConfirmRegisterPopupStatus}
            email={email}
          />
        </div>
      </div>
    </>
  );
};
