import { Select } from "formik-material-ui";
import { MenuItem } from "@material-ui/core";
export const SelectMeTeDhenaStatike = (props) => {
  // if (props.field.name === "kthimi") {
  //   console.log(props);
  // }
  return (
    <div>
      <Select
        variant="outlined"
        style={{
          boxShadow:
            props?.meta?.touched && props?.meta?.error
              ? "inset 0px 0px 0px 1px #f00"
              : "",
        }}
        color={
          props?.meta?.touched && props?.meta?.error ? "secondary" : "primary"
        }
        fullWidth
        {...props}
      >
        <MenuItem disableRipple defaultChecked value="empty">
          {props?.infoText?.default}
        </MenuItem>
        {props?.data?.map((sh) => (
          <MenuItem key={sh.id} disableRipple value={sh}>
            {sh[`${props.name}`]}
          </MenuItem>
        ))}
      </Select>
      {props?.meta?.touched && props?.meta?.error && (
        <p
          style={{ width: "100%" }}
          className="forma-aplikimit-inputet-errorText"
        >
          {props?.meta?.error}
        </p>
      )}
    </div>
  );
};
