import "./SingleKomunikim.scss";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Collapse,
  Button,
  IconButton,
  Grid,
  useMediaQuery,
} from "@material-ui/core";
import {
  ArrowDropDown,
  ArrowDropUp,
  CallMade,
  DraftsOutlined,
  EmailOutlined,
  InsertDriveFile,
} from "@material-ui/icons";
import { useState } from "react";
import { constants } from "../utils/constants";
import { formatDate } from "../utils/formatDate";
import { useUserStore } from "../context/user";
import { setSeenRequest } from "../actions/komunikim";
import { useAuthorizedGraphqlClient } from "../utils/useAuthorizedGraphqlClient";
import ReactLinkify from "react-linkify";

export const SingleKomunikim = ({
  komunikim,
  lastElementRef,
  // qytetari,
  // showReply,
  // setKomunikimPopup,
  // refetchKomunikimet,
}) => {
  const [expanded, setExpanded] = useState(false);

  const { emri, mbiemri } = useUserStore();
  const sentByUser = komunikim?.perdoruesit === null;
  const nameOfSender = sentByUser
    ? `${emri} ${mbiemri}`
    : komunikim?.perdoruesit?.institucionet === null
    ? "Koordinatori"
    : `${komunikim?.perdoruesit?.institucionet?.institucioni}`;

  const matches = useMediaQuery("(max-width:865px)");
  const matchesTwo = useMediaQuery("(max-width:600px)");

  const [seen, setSeen] = useState(komunikim?.seen_by_qytetar);
  const client = useAuthorizedGraphqlClient();

  return (
    <div style={{ marginTop: "15px" }}>
      <Card ref={lastElementRef}>
        <CardHeader
          avatar={
            <div style={{ height: "100%" }}>
              {sentByUser ? (
                <CallMade style={{ stroke: "#42A700", strokeWidth: 2 }} />
              ) : seen ? (
                <DraftsOutlined />
              ) : (
                <EmailOutlined />
              )}
            </div>
          }
          action={
            <div>
              <IconButton
                style={{ margin: "-5px" }}
                onClick={async () => {
                  setExpanded(!expanded);
                  if (!seen) {
                    const { ok } = await setSeenRequest({
                      client,
                      komunikimId: komunikim?.id,
                    });
                    if (ok) {
                      setSeen(true);
                    }
                  }
                }}
              >
                {expanded ? <ArrowDropUp /> : <ArrowDropDown />}
              </IconButton>
            </div>
          }
          title={
            <Grid container wrap={matches ? "wrap" : "nowrap"}>
              <Typography
                style={{
                  marginLeft: "2vw",
                  marginRight: "2vw",
                  minWidth: "5vw",
                  color: "#444444",
                  fontWeight: "500",
                }}
              >
                {nameOfSender}
              </Typography>
              <Grid
                // alignContent="center"
                // alignItems="center"
                // wrap="nowrap"
                style={{
                  marginLeft: "4vw",
                  marginRight: "4vw",
                  marginTop: matchesTwo ? "1vh" : "",
                  marginBottom: matchesTwo ? "1vh" : "",
                  flexGrow: "1",
                  display: "flex",
                  width: matchesTwo ? "100%" : "",
                }}
              >
                {komunikim?.files?.length > 0 && !expanded && (
                  <InsertDriveFile
                    style={{
                      fill: "#FBBA07",
                      alignSelf: matchesTwo ? "flex-start" : "",
                      marginRight: 8,
                      marginLeft: "-2vw",
                    }}
                  />
                )}
                <ReactLinkify
                  componentDecorator={(decoratedHref, decoratedText, key) => (
                    <a
                      target="_blank"
                      href={decoratedHref}
                      key={key}
                      rel="noreferrer"
                    >
                      {decoratedText}
                    </a>
                  )}
                >
                  <Typography
                    style={{
                      flexGrow: "1",
                      minWidth: "61%",
                      // display: "inline-block",
                      whiteSpace: expanded ? "pre-line" : "",
                      wordBreak: "break-word",
                    }}
                  >
                    {expanded
                      ? `${komunikim?.tekst}`
                      : `${komunikim?.tekst.substring(0, 75)}...`}
                  </Typography>
                </ReactLinkify>
              </Grid>

              <Typography
                style={{
                  minWidth: "max-content",
                  marginLeft: "2vw",
                  marginRight: "2vw",
                  fontStyle: "italic",
                }}
              >
                {formatDate(komunikim?.created_at)}
              </Typography>
            </Grid>
          }
          style={{
            alignItems: "flex-start",
            paddingBottom: "13px",
          }}
        />
        <Collapse in={expanded} timeout="auto">
          <CardContent>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <div
                style={{
                  marginRight: "15px",
                }}
              >
                {komunikim?.files &&
                  komunikim?.files.map((sh) => {
                    const lastIndexOfDot = sh?.name.lastIndexOf(".");
                    const fullName = sh?.name.slice(0, lastIndexOfDot - 1);
                    const extenstion = sh?.name.slice(lastIndexOfDot);
                    const shortName = `${fullName.substring(
                      0,
                      43
                    )}..${extenstion}`;
                    return (
                      <a
                        href={`${constants.fileUploadUrl}/?id=${sh?.id}`}
                        download={`${sh?.name}`}
                        key={sh?.id}
                      >
                        <Button
                          style={{
                            textAlign: "center",
                            border: "1px lightgray dashed",
                            borderRadius: "5px",
                            marginTop: "15px",
                            marginRight: "15px",
                            // textTransform: "none",
                          }}
                          startIcon={
                            <InsertDriveFile style={{ fill: "#FBBA07" }} />
                          }
                        >
                          {shortName}
                        </Button>
                      </a>
                    );
                  })}
              </div>
              {/* {showReply && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "3px",
                    border: "2px #707070 solid",
                    borderRadius: "5px",
                    width: "fit-content",
                    height: "fit-content",
                    cursor: "pointer",
                  }}
                  onClick={handleNewKominikimPopup}
                >
                  <Reply style={{ fill: "#707070" }} />
                </div>
              )} */}
            </div>
          </CardContent>
        </Collapse>
      </Card>
    </div>
  );
};
