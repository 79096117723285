import { Kontributet } from "./Kontributet";
// import { Komunikimet } from "./Komunikimet";
import { MenuBar } from "../components/MenuBar";
import { Route, Switch } from "react-router-dom";
import { FormulariAplikimitPage } from "./FormulariAplikimitPage";

export const Paneli = () => {
  return (
    <div 
    style={{ minHeight: "100vh" }}
    >
      <MenuBar />
      <div style={{ width: "90%", margin: "0 auto", marginTop: "2vh" }}>
        <Switch>
          <Route exact path="/kontributet" component={Kontributet} />
          <Route
            exact
            path="/kontributet/krijo"
            component={FormulariAplikimitPage}
          />
          {/* <Route
            exact
            path="/kontributet/:id/komunikimet"
            component={Komunikimet}
          /> */}
        </Switch>
      </div>
    </div>
  );
};
