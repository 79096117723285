import { Button, Grid, useMediaQuery } from "@material-ui/core";
import {
  CheckCircleRounded,
  InsertDriveFile,
  WatchLater,
} from "@material-ui/icons";
import { constants } from "../utils/constants";
import { formatDate } from "../utils/formatDate";
import "./DetajetEQytetarit.scss";

export const DetajetEQytetarit = ({ qytetari, kontributi }) => {
  const matchesOne = useMediaQuery("(max-width:875px)");
  const matchesTwo = useMediaQuery("(max-width:585px)");
  const meetingDone =
    new Date(kontributi.eventet.ora_takimit).getTime() + 5400 < Date.now()
      ? true
      : false;
  return (
    <Grid
      container
      alignItems="center"
      justify="space-evenly"
      className="detajet"
      // xs={12}
    >
      <Grid item xs={matchesOne ? 12 : 9}>
        <Grid container justify="flex-start">
          <Grid
            item
            xs={matchesTwo ? 12 : 4}
            className="detajet-cell"
            style={{
              borderRight: matchesTwo ? "" : "0.1px dashed #545454",
              borderBottom: "0.1px dashed #545454",
            }}
          >
            <div className="detajet-cell-item">
              {qytetari?.emri} {qytetari?.mbiemri}
            </div>
            <div className="detajet-cell-item">{kontributi?.case_nr}</div>
          </Grid>
          <Grid
            item
            xs={matchesTwo ? 12 : 4}
            className="detajet-cell"
            style={{
              borderRight: matchesTwo ? "" : "0.1px dashed #545454",
              borderBottom: "0.1px dashed #545454",
            }}
          >
            <div className="detajet-cell-item">
              {kontributi?.nivelet_e_edukimit?.info}
            </div>
          </Grid>
          <Grid
            item
            xs={matchesTwo ? 12 : 4}
            className="detajet-cell"
            style={{
              borderRight: matchesOne ? "" : "0.1px dashed #545454",
              borderBottom: "0.1px dashed #545454",
            }}
          >
            <div className="detajet-cell-item">
              {kontributi?.menyrat_e_angazhimit?.info}
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            className="detajet-cell"
            xs={matchesTwo ? 12 : 4}
            style={{
              borderRight: matchesTwo ? "" : "0.1px dashed #545454",
              borderBottom: matchesOne ? "0.1px dashed #545454" : "",
            }}
          >
            <div className="detajet-cell-item">
              {kontributi?.shtetet?.shteti}
            </div>
            {kontributi?.kthimi !== null && (
              <div
                className="detajet-cell-item"
                style={{
                  backgroundColor: "#9D9B9B",
                  borderRadius: "5px",
                  width: "max-content",
                  padding: "2px 4px",
                  color: "white",
                }}
              >
                {kontributi?.kthimi ? "do kthehet" : "nuk kthehet"}
              </div>
            )}
          </Grid>
          <Grid
            item
            className="detajet-cell"
            xs={matchesTwo ? 12 : 4}
            style={{
              borderRight: matchesTwo ? "" : "0.1px dashed #545454",
              borderBottom: matchesOne ? "0.1px dashed #545454" : "",
            }}
          >
            <div className="detajet-cell-item">{kontributi?.profesioni}</div>
            <div className="detajet-cell-item">
              {kontributi?.vite_eksperience === 0
                ? `më pak se 1 vit`
                : kontributi?.vite_eksperience === 10
                ? `më shumë se ${kontributi?.vite_eksperience} vjet`
                : `${kontributi?.vite_eksperience} vjet`}
            </div>
          </Grid>
          <Grid
            item
            className="detajet-cell"
            xs={matchesTwo ? 12 : 4}
            style={{
              borderRight: matchesOne ? "" : "0.1px dashed #545454",
              borderBottom: matchesOne ? "0.1px dashed #545454" : "",
            }}
          >
            <div className="detajet-cell-item" style={{ marginLeft: "30px" }}>
              {kontributi?.eventet?.ora_takimit ? (
                <Grid
                  container
                  wrap="nowrap"
                  alignItems="center"
                  style={{ marginLeft: "-30px" }}
                >
                  {meetingDone ? (
                    <CheckCircleRounded
                      style={{ marginRight: 5, fill: "#52C507" }}
                    />
                  ) : (
                    <WatchLater style={{ marginRight: 5, fill: "#F9B701" }} />
                  )}

                  <Grid item>
                    {kontributi?.eventet?.status_kontributet?.info}
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  container
                  wrap="nowrap"
                  alignItems="center"
                  style={{ marginLeft: "-30px" }}
                >
                  <WatchLater style={{ marginRight: 5, fill: "gray" }} />
                  <Grid item>
                    {kontributi?.eventet?.status_kontributet?.info}
                  </Grid>
                </Grid>
              )}
            </div>
            <div className="detajet-cell-item" style={{ marginLeft: "30px" }}>
              {kontributi?.eventet?.ora_takimit
                ? formatDate(kontributi?.eventet?.ora_takimit)
                : ""}
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container={matchesOne ? true : false}
        style={{
          marginTop: matchesOne ? "1vh" : "",
          justifyContent: "flex-start",
          display: "flex",
          flexDirection: "column",
        }}
        // justify={matchesOne ? "" : "flex-start"}
        // xs={ matchesOne ? 12 : 3}
      >
        {kontributi?.files &&
          kontributi?.files.map((sh) => {
            const lastIndexOfDot = sh?.name.lastIndexOf(".");
            const fullName = sh?.name.slice(0, lastIndexOfDot - 1);
            const extenstion = sh?.name.slice(lastIndexOfDot);
            const shortName = `${fullName.substring(0, 15)}..${extenstion}`;
            return (
              <div key={sh?.id}>
                <a
                  href={`${constants.fileUploadUrl}/?id=${sh?.id}`}
                  download={`${sh?.name}`}
                >
                  <Button
                    style={{
                      textAlign: "left",
                    }}
                    startIcon={<InsertDriveFile style={{ fill: "#FBBA07" }} />}
                  >
                    {shortName}
                  </Button>
                </a>
              </div>
            );
          })}
      </Grid>
    </Grid>
  );
};
