import { useEffect } from "react";
import decodeToken from "jwt-decode";
import "./App.scss";
import { useUserStore } from "./context/user";
import { QueryClientProvider, QueryClient } from "react-query";
// import { SnackbarProvider } from "notistack";

import { Router } from "react-router-dom";
import history from "./utils/history";
import { refreshToken } from "./actions/auth";
import { Footer } from "./components/Footer";
import { Routing } from "./Routing";

function App() {
  // check whether the user has logged in before or not
  const {
    checkIfLoggedIn,
    token,
    logIn,
    logOut,
    redirectPath,
    registerFinished,
    emri,
    mbiemri,
    email,
    userId,
    role,
  } = useUserStore();
  useEffect(() => {
    checkIfLoggedIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, emri, mbiemri, email, userId, role]);

  useEffect(() => {
    if (token && registerFinished) {
      try {
        const decodedToken = decodeToken(`${token}`);
        if (decodedToken.exp > Date.now() / 1000) {
          const {
            email,
            "https://hasura.io/jwt/claims": {
              "x-hasura-default-role": role,
              emri,
              mbiemri,
              "x-hasura-user-id": userId,
            },
          } = decodedToken;
          logIn({
            userId,
            email,
            role,
            token,
            emri,
            mbiemri,
          });
          if (redirectPath !== "") {
            history.push(redirectPath);
          } else {
            history.push("/kontributet");
          }
        } else if (decodedToken?.exp < Date.now() / 1000 + 1790) {
          refreshToken({ token, logIn, history, logOut, redirectPath });
        } else {
          logOut();
        }
      } catch (e) {
        console.log({ e });
        logOut();
      }
    }
  }, [logIn, logOut, redirectPath, registerFinished, token]);

  // react query setup
  const queryClient = new QueryClient({
    defaultOptions: { queries: { refetchOnWindowFocus: false } },
  });

  return (
    <QueryClientProvider client={queryClient}>
      {/* <SnackbarProvider maxSnack={3}> */}
      <Router history={history}>
        <Routing />
      </Router>
      <Footer />
      {/* </SnackbarProvider> */}
    </QueryClientProvider>
  );
}

export default App;
