import { GraphQLClient } from "graphql-request";
import { useUserStore } from "../context/user";
import { constants } from "./constants";

export const useAuthorizedGraphqlClient = () => {
  const { token } = useUserStore();
  const graphqlClient = new GraphQLClient(constants.hasuraUrl);

  graphqlClient.setHeader("Authorization", `Bearer ${token}`);

  return graphqlClient;
};
