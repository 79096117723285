import { Button, Grid, CircularProgress } from "@material-ui/core";
import { FastField, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { registerUser } from "../actions/auth";
import { validation } from "../utils/validation";

export const RegisterComponent = ({
  showRegister,
  setConfirmRegisterPopupStatus,
  setEmail,
}) => {
  return (
    <div style={{ display: showRegister ? "" : "none" }}>
      <div className="forma-aplikimit-inputet">
        <Formik
          initialValues={{
            emri: "",
            mbiemri: "",
            email: "",
            password: "",
            confirmPassword: "",
          }}
          validationSchema={validation.regjistrimi}
          onSubmit={async (values, { setStatus, setSubmitting }) => {
            const { ok } = await registerUser({ values });

            if (ok) {
              setEmail(values.email);
              setConfirmRegisterPopupStatus(true);
              setStatus({});
            } else {
              setStatus({
                // message: "Një gabim ka ndodhur, provoni përsëri.",
                message: "Kjo adresë e-mail rezulton e regjistruar më parë.",
              });
            }
          }}
        >
          {({ submitForm, isSubmitting, isValid, status, values, errors }) => {
            return (
              <Form>
                <p className="forma-aplikimit-inputet-labels">Emër *</p>
                <FastField name="emri">
                  {(props) => (
                    <TextField
                      {...props}
                      variant="outlined"
                      fullWidth
                      placeholder="Plotësoni emrin"
                    />
                  )}
                </FastField>
                <p className="forma-aplikimit-inputet-labels">Mbiemër *</p>
                <FastField name="mbiemri">
                  {(props) => (
                    <TextField
                      {...props}
                      variant="outlined"
                      fullWidth
                      placeholder="Plotësoni mbiemrin"
                    />
                  )}
                </FastField>
                <p className="forma-aplikimit-inputet-labels">
                  Adresa e-mail *
                </p>
                <FastField name="email">
                  {(props) => (
                    <TextField
                      {...props}
                      variant="outlined"
                      placeholder="adresa@domain.com"
                      fullWidth
                    />
                  )}
                </FastField>
                <p className="forma-aplikimit-inputet-labels">Fjalëkalimi *</p>
                <FastField name="password">
                  {(props) => (
                    <TextField
                      {...props}
                      type="password"
                      variant="outlined"
                      fullWidth
                      placeholder="Plotësoni fjalëkalimin"
                    />
                  )}
                </FastField>
                <p className="forma-aplikimit-inputet-labels">
                  Përsërit fjalëkalimin *
                </p>
                <FastField name="confirmPassword">
                  {(props) => (
                    <TextField
                      {...props}
                      type="password"
                      variant="outlined"
                      placeholder="Përsërit fjalëkalimin"
                      fullWidth
                    />
                  )}
                </FastField>

                <Grid
                  alignItems="center"
                  container
                  style={{
                    marginTop: "64px",
                    flexDirection: "column",
                  }}
                >
                  <Button
                    disableElevation
                    disableRipple
                    disableFocusRipple
                    variant="contained"
                    size="large"
                    style={{
                      backgroundColor:
                        !isValid || isSubmitting ? "" : "#CF0429",
                      color: !isValid || isSubmitting ? "" : "white",
                      textTransform: "none",
                      minWidth: "300px",
                      maxWidth: "300px",
                      minHeight: "43px",
                    }}
                    disabled={!isValid || isSubmitting}
                    onClick={submitForm}
                  >
                    {isSubmitting ? (
                      <CircularProgress
                        style={{
                          color: "#545454",
                          width: "24px",
                          height: "24px",
                        }}
                      />
                    ) : (
                      "Regjistrohu"
                    )}
                  </Button>
                  {!isValid && (
                    <p
                      style={{
                        textAlign: "center",
                        marginBottom: "0",
                        fontSize: "0.75rem",
                        color: "#f44336",
                        lineHeight: "1.66",
                        letterSpacing: "0.03333em",
                      }}
                    >
                      Ju lutem sigurohuni që i keni plotësuar të gjitha fushat e
                      detyrueshme.
                    </p>
                  )}
                  <p>{status?.message}</p>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};
