import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Field, Formik, Form } from "formik";
import { TextField } from "formik-material-ui";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { confirmUserRegistration, resendOtpCode } from "../actions/auth";
import { useUserStore } from "../context/user";
import { validation } from "../utils/validation";

export const ConfirmRegisterPopUp = ({
  confirmRegisterPopupStatus,
  setConfirmRegisterPopupStatus,
  email,
}) => {
  const { logIn, setRegisterFinished } = useUserStore();

  useEffect(() => {
    if (confirmRegisterPopupStatus) {
      setRegisterFinished({ registerFinished: false });
    }
  }, [confirmRegisterPopupStatus, setRegisterFinished]);

  const [success, setSuccess] = useState(false);

  const handleClose = () => {
    setConfirmRegisterPopupStatus(false);
    setRegisterFinished({ registerFinished: true });
  };

  const [resendOtpStatus, setResendOtpStatus] = useState("");
  const resendOtpMutation = useMutation(async ({ values }) => {
    const { ok, res } = await resendOtpCode({ values });
    return { ok, res };
  });

  return (
    <Dialog
      open={confirmRegisterPopupStatus}
      maxWidth="xs"
      onClose={() => setConfirmRegisterPopupStatus(false)}
    >
      <DialogTitle>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <span style={{ flexGrow: "1" }}>Konfirmo krijimin e llogarisë</span>
          <Close
            style={{
              cursor: "pointer",
              marginTop: "3px",
            }}
            onClick={() => setConfirmRegisterPopupStatus(false)}
          />
        </div>
      </DialogTitle>
      <DialogContent>
        {success ? (
          <>
            Llogaria juaj u krijua me sukses.
            <Button
              disableElevation
              disableRipple
              variant="contained"
              size="large"
              fullWidth
              style={{
                backgroundColor: "#CF0429",
                color: "white",
                textTransform: "none",
                marginTop: "40px",
                marginBottom: "20px",
              }}
              onClick={handleClose}
            >
              Vazhdo me aplikimin
            </Button>
          </>
        ) : (
          <Formik
            initialValues={{
              email,
              code: "",
            }}
            validationSchema={validation.confirmRegister}
            onSubmit={async (values, { setStatus }) => {
              const { ok, res } = await confirmUserRegistration({
                values,
              });
              if (ok) {
                const {
                  user_id,
                  email,
                  user_roles,
                  access_token,
                  emri,
                  mbiemri,
                } = res;

                logIn({
                  userId: user_id,
                  email,
                  role: user_roles,
                  token: access_token,
                  emri,
                  mbiemri,
                });

                setSuccess(true);
              } else {
                setStatus({
                  ok,
                  message: "Ky veprim nuk mund të kryhet për momentin, ju lutem provoni përsëri.",
                });
              }
            }}
          >
            {({ submitForm, isSubmitting, status, isValid, values }) => {
              return (
                <>
                  <DialogContentText>
                    Ju ka ardhur një kod 5 shifror në emalin tuaj, vendoseni më
                    poshtë për të konfirmuar krijimin e llogarisë.
                  </DialogContentText>
                  <Form>
                    <p className="forma-aplikimit-inputet-labels">Kodi *</p>
                    <Field name="code">
                      {(props) => (
                        <TextField
                          {...props}
                          variant="outlined"
                          fullWidth
                          placeholder="12345"
                        />
                      )}
                    </Field>

                    <Button
                      style={{
                        marginTop: "5px",
                        marginBottom: 0,
                        cursor: "pointer",
                        textDecoration: "underline",
                        textTransform: "none",
                        color: "#cf0429",
                        width: "fit-content",
                        // padding: 0,
                      }}
                      onClick={() => {
                        resendOtpMutation.mutateAsync(
                          { values },
                          {
                            onSuccess: (data, variables, context) => {
                              if (data?.ok) {
                                setResendOtpStatus({
                                  message:
                                    "Kodi i ri u dërgua në email-in tuaj.",
                                });
                              } else {
                                setResendOtpStatus({
                                  message:
                                    "Ky veprim nuk mund të kryhet për momentin, ju lutem provoni përsëri.",
                                });
                              }
                            },
                          }
                        );
                      }}
                      disabled={resendOtpMutation.isLoading}
                    >
                      {resendOtpMutation.isLoading ? (
                        <CircularProgress
                          size={12}
                          style={{ color: "#cf0429" }}
                        />
                      ) : (
                        "Ridërgo kodin"
                      )}
                    </Button>

                    <p
                      style={{
                        marginBottom: 0,
                        marginTop: "3px",
                        fontSize: "16px",
                      }}
                    >
                      {resendOtpStatus.message}
                    </p>
                    <Button
                      disableElevation
                      disableRipple
                      disableFocusRipple
                      variant="contained"
                      size="large"
                      fullWidth
                      style={{
                        backgroundColor:
                          !isValid || isSubmitting ? "" : "#CF0429",
                        color: !isValid || isSubmitting ? "" : "white",
                        textTransform: "none",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                      disabled={!isValid || isSubmitting}
                      onClick={submitForm}
                    >
                      {isSubmitting ? (
                        <CircularProgress
                          style={{
                            color: "#545454",
                            width: "24px",
                            height: "24px",
                          }}
                        />
                      ) : (
                        "Konfimo"
                      )}
                    </Button>
                    {status?.ok === false && (
                      <p style={{ marginTop: "-10px", textAlign: "center" }}>
                        {status?.message}
                      </p>
                    )}
                  </Form>
                </>
              );
            }}
          </Formik>
        )}
      </DialogContent>
    </Dialog>
  );
};
