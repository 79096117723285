import { constants } from "../utils/constants";

export const refreshToken = async ({
  token,
  logIn,
  history,
  logOut,
  redirectPath,
}) => {
  try {
    const resp = await fetch(`${constants.authUrl}/refresh`, {
      method: "POST",
      body: JSON.stringify({ token }),
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      redirect: "follow",
      referrerPolicy: "no-referrer",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (resp.ok) {
      const { user_id, email, user_roles, access_token, emri, mbiemri } =
        await resp.json();
      logIn({
        userId: user_id,
        email,
        role: user_roles,
        token: access_token,
        emri,
        mbiemri,
      });
      if (redirectPath !== "") {
        history.push(redirectPath);
      } else {
        history.push("/kontributet");
      }
    }
  } catch (e) {
    console.log({ e });
    logOut();
    const emptyRes = {};
    return { ok: false, res: emptyRes };
  }
};

export const logInUser = async ({ values }) => {
  try {
    const { email, password } = values;
    let cleanedEmail = email.toLowerCase();
    cleanedEmail = cleanedEmail.trim();
    const resp = await fetch(`${constants.authUrl}/login`, {
      method: "POST",
      body: JSON.stringify({
        email: cleanedEmail,
        password,
      }),
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      redirect: "follow",
      referrerPolicy: "no-referrer",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (resp.ok) {
      const res = await resp.json();
      return { ok: true, res };
    }
    const res = await resp.json();
    throw res;
  } catch (e) {
    console.log({ e });
    return { ok: false, res: e };
  }
};

export const registerUser = async ({ values }) => {
  try {
    const { emri, mbiemri, email, password, confirmPassword } = values;
    let cleanedEmail = email.toLowerCase();
    cleanedEmail = cleanedEmail.trim();
    const resp = await fetch(`${constants.authUrl}/signup`, {
      method: "POST",
      body: JSON.stringify({
        emri,
        mbiemri,
        email: cleanedEmail,
        password,
        confirmPassword,
      }),
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      redirect: "follow",
      referrerPolicy: "no-referrer",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (resp.ok) {
      return { ok: true };
    }
    // const res = await resp.json();
    // throw res;
  } catch (e) {
    console.log({ e });
    return { ok: false };
  }
};

export const confirmUserRegistration = async ({ values, setSuccess }) => {
  try {
    const { code, email } = values;
    let cleanedEmail = email.toLowerCase();
    cleanedEmail = cleanedEmail.trim();
    const resp = await fetch(`${constants.authUrl}/code`, {
      method: "POST",
      body: JSON.stringify({
        code,
        email: cleanedEmail,
      }),
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      redirect: "follow",
      referrerPolicy: "no-referrer",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    const res = await resp.json();
    if (resp.ok) {
      return { ok: true, res };
    } else {
      throw res;
    }
  } catch (e) {
    console.log({ e });
    const emptyResp = {};
    return { ok: false, res: emptyResp };
  }
};

export const resendOtpCode = async ({ values }) => {
  try {
    const { email } = values;
    let cleanedEmail = email.toLowerCase();
    cleanedEmail = cleanedEmail.trim();
    const resp = await fetch(`${constants.authUrl}/reset`, {
      method: "POST",
      body: JSON.stringify({
        email: cleanedEmail,
      }),
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      redirect: "follow",
      referrerPolicy: "no-referrer",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    const res = await resp.json();
    if (resp.ok) {
      return { ok: true, res };
    } else {
      throw res;
    }
  } catch (e) {
    console.log({ e });
    const emptyResp = {};
    return { ok: false, res: emptyResp };
  }
};

export const resetPassword = async ({ values }) => {
  try {
    const { email, otpCode, newPassword } = values;
    let cleanedEmail = email.toLowerCase();
    cleanedEmail = cleanedEmail.trim();
    const resp = await fetch(`${constants.authUrl}/newPass`, {
      method: "POST",
      body: JSON.stringify({
        email: cleanedEmail,
        code: otpCode,
        password: newPassword,
      }),
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      redirect: "follow",
      referrerPolicy: "no-referrer",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    const res = await resp.json();
    if (resp.ok) {
      return { ok: true, res };
    } else {
      throw res;
    }
  } catch (e) {
    console.log({ e });
    const emptyResp = {};
    return { ok: false, res: emptyResp };
  }
};
