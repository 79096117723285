export const constants = {
  maxUploadSize: 15000000,
  nrMaxIKontributeve: 1,
  nrMaxIFushaveTeInteresit: 2,
  authUrl: process.env.REACT_APP_AUTH_URL || "https://auth.gatipershqiperine.al",
  funcUrl: process.env.REACT_APP_FUNCTION_URL || "https://func.gatipershqiperine.al",
  hasuraUrl:
    process.env.REACT_APP_HASURA_URL || "https://api.gatipershqiperine.al/v1/graphql",
  //  "https://gatipershqiperine.azurewebsites.net/v1/graphql",
  fileUploadUrl:
    process.env.REACT_APP_FILE_UPLOAD_URL ||
    "https://func.gatipershqiperine.al/api/uploads",

  komunikimetLimit: 5,
  supportedFileFormats: [
    ".doc",
    ".docs",
    "image/jpg",
    "image/png",
    "image/jpeg",
    "application/pdf",
    "application/pdf",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/msword",
  ],
  gjiniteAlt: [
    { id: 1, gjinia: "Mashkull", dbValue: "mashkull" },
    { id: 2, gjinia: "Femër", dbValue: "femer" },
  ],
  nivelEdukimiAlt: [
    { id: 1, nivelEdukimi: "Bachelor", dbValue: "bachelor" },
    {
      id: 2,
      nivelEdukimi: "Master Profesional",
      dbValue: "master_profesional",
    },
    { id: 3, nivelEdukimi: "Master Shkencor", dbValue: "master_shkencor" },
    { id: 4, nivelEdukimi: "Doktoraturë", dbValue: "doktorature" },
    { id: 5, nivelEdukimi: "Docent", dbValue: "docent" },
    { id: 5, nivelEdukimi: "Asistent Profesor", dbValue: "asistent_profesor" },
    { id: 6, nivelEdukimi: "Profesor", dbValue: "profesor" },
  ],
  viteEksperienceAlt: [
    { id: 0, dbValue: 0, viteEksperience: "më pak se 1 vit" },
    { id: 1, dbValue: 1, viteEksperience: "1 vit" },
    { id: 2, dbValue: 2, viteEksperience: "2 vjet" },
    { id: 3, dbValue: 3, viteEksperience: "3 vjet" },
    { id: 4, dbValue: 4, viteEksperience: "4 vjet" },
    { id: 5, dbValue: 5, viteEksperience: "5 vjet" },
    { id: 6, dbValue: 6, viteEksperience: "6 vjet" },
    { id: 7, dbValue: 7, viteEksperience: "7 vjet" },
    { id: 8, dbValue: 8, viteEksperience: "8 vjet" },
    { id: 9, dbValue: 9, viteEksperience: "9 vjet" },
    { id: 10, dbValue: 10, viteEksperience: "10+ vjet" },
  ],
  deshiraKontributitAlt: [
    {
      id: 1,
      kthimi: "Duke qëndruar në shtetin ku jetoni",
      dbValue: false,
    },
    { id: 2, kthimi: "Duke u kthyer në Shqipëri", dbValue: true },
  ],
  menyraAngazhimitAlt: [
    {
      id: 1,
      menyraAngazhimit: "Dëshiroj të punësohem në Shqipëri",
      dbValue: "punesohem_ne_shqiperi",
    },
    {
      id: 2,
      menyraAngazhimit: "Dëshiroj të punoj si nëpunës digjital",
      dbValue: "punoj_si_nepunes_digjital",
    },
    {
      id: 3,
      menyraAngazhimit: "Dëshiroj të punoj si ekspert i jashtëm",
      dbValue: "punoj_si_ekspert_jashtem",
    },
    {
      id: 4,
      menyraAngazhimit: "Dëshiroj të zhvilloj një projekt-ide",
      dbValue: "zhvilloj_projekt_ide",
    },
  ],
  requiredErrorMessage: "Fusha më lart është e detyrueshme.",
  shortPassErrorMessage:
    "Fjalëkalimi juaj është shumë i shkurtër, ai duhet të përmbajë të paktën 8 karaktere.",
  passWrongFormatErrorMessage:
    "Fjalëkalimi juaj duhet të përmbajë të paktën një karakter të vogël, një karakter të madh, një numër dhe një karakter special.",
  notSamePassErrorMessage:
    "Fjalëkalimi nuk është i njëjtë me atë të vendosur më lart.",
  dokumentTooBigErrorMessage: "Dokumenti është shumë i madh për tu dërguar.",
  dokumentWrongFormatErrorMessage:
    "Dokumenti nuk është në një prej formateve të lejuara.",
  emailWrongFormatErrorMessage: "Emaili nuk është në formatin e duhur.",
  codeShortErrorMessage: "Kodi duhet të jetë të paktën 5 karaktere.",
};
