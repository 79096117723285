export function formatDate(date) {
  let parsed_time;
  let parsed_date;
  const time_options = {
    hour: "numeric",
    minute: "numeric",
    hour12: false,
    hc: 24,
  };
  const date_options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };
  try {
    const date_not_parsed = new Date(date);
    parsed_time = new Intl.DateTimeFormat("en-GB", time_options).format(
      date_not_parsed
    );
    parsed_date = new Intl.DateTimeFormat("en-GB", date_options).format(
      date_not_parsed
    );
  } catch {
    return null;
  }
  return `${parsed_time} ${parsed_date}`;
}
