import { SvgIcon } from "@material-ui/core";

export const InfoIcon = (props) => (
  <SvgIcon {...props}>
    <path
      d="M8 16C3.57694 16 0 12.4268 0 8C0 3.57703 3.57325 0 8 0C12.4231 0 16 3.57325 16 8C16 12.423 12.4268 16 8 16Z"
      fill="#CF0429"
    />
    <path
      d="M8.9375 13.1562H7.0625C6.80362 13.1562 6.59375 12.9464 6.59375 12.6875V7.0625C6.59375 6.80362 6.80362 6.59375 7.0625 6.59375H8.9375C9.19637 6.59375 9.40625 6.80362 9.40625 7.0625V12.6875C9.40625 12.9464 9.19637 13.1562 8.9375 13.1562Z"
      fill="white"
    />
    <path
      d="M8 5.65625C7.22459 5.65625 6.59375 5.02541 6.59375 4.25C6.59375 3.47459 7.22459 2.84375 8 2.84375C8.77541 2.84375 9.40625 3.47459 9.40625 4.25C9.40625 5.02541 8.77541 5.65625 8 5.65625Z"
      fill="white"
    />
  </SvgIcon>
);
