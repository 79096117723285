import { makeStyles } from "@material-ui/core";
import { FastField } from "formik";
import { TextField } from "formik-material-ui";
import { SelectShteti } from "./SelectShteti";

const useStyles = makeStyles((theme) => ({
  input: {
    background: "white",
  },
}));

export const DetajetEEdukimit = (formProps) => {
  const classes = useStyles();
  return (
    <div
      style={{
        backgroundColor: "#f6f6f6",
        padding: "35px 50px",
        marginBottom: "20px",
      }}
    >
      <p
        className="forma-aplikimit-inputet-labels"
        style={{ marginTop: "-5px" }}
      >
        Fusha e studimit në {formProps?.niveliEdukimitEmer}
      </p>
      <FastField name={`${formProps?.name}.fushaStudimit`}>
        {(props) => (
          <TextField
            {...props}
            variant="outlined"
            placeholder={`${formProps?.infoText?.fushaStudimit}`}
            fullWidth
            inputProps={{ className: classes.input }}
            // style={{ backgroundColor: "white" }}
            // inputProps={{ backgr" }}
          />
        )}
      </FastField>
      <p className="forma-aplikimit-inputet-labels">
        Universiteti ku keni kryer studimet {formProps?.niveliEdukimitEmer}
      </p>
      <FastField name={`${formProps?.name}.universiteti`}>
        {(props) => (
          <TextField
            {...props}
            variant="outlined"
            placeholder={formProps?.infoText?.universiteti}
            fullWidth
            inputProps={{ className: classes.input }}
          />
        )}
      </FastField>
      <p className="forma-aplikimit-inputet-labels">
        Shteti ku keni kryer studimet e {formProps?.niveliEdukimitEmer}
      </p>
      <FastField name={`${formProps?.name}.shteti`}>
        {(props) => (
          <SelectShteti
            infoText={{ default: "Zgjidhni shtetin" }}
            data={formProps?.data}
            name={`${formProps?.name}.shteti`}
            {...props}
            inputProps={{ className: classes.input }}
          />
        )}
      </FastField>
    </div>
  );
};
