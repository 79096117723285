import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { FastField, Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { createKomunikim } from "../actions/komunikim";
import { useUserStore } from "../context/user";
import { useAuthorizedGraphqlClient } from "../utils/useAuthorizedGraphqlClient";
import { validation } from "../utils/validation";
import { MultiUpload } from "./MultiUpload";

export const KomunikimIRiPopup = ({
  komunikimPopup,
  setKomunikimPopup,
  kontributi,
  refetchKomunikimet,
  setKomunikimetState,
  setSkip,
  // setCanIncreaseSkip
}) => {
  const handleClose = () => {
    setKomunikimPopup(false);
  };
  let kontributId;
  try {
    kontributId = kontributi[0].id;
  } catch {}
  const client = useAuthorizedGraphqlClient();
  const { token } = useUserStore();
  return (
    <Dialog open={komunikimPopup} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <span style={{ flexGrow: "1", color: "#545454" }}>
            Komunikim i ri
          </span>
          <Close
            style={{
              cursor: "pointer",
              marginTop: "3px",
            }}
            onClick={handleClose}
          />
        </div>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            permbajtja: "",
            files: [],
          }}
          validationSchema={validation.komunikimIRi}
          onSubmit={async (values, { setStatus }) => {
            // console.log({ values });
            const { ok } = await createKomunikim({
              client,
              kontributId,
              values,
              token,
            });
            if (ok) {
              setKomunikimPopup(false);
              // setCanIncreaseSkip(false)
              setSkip(0);
              setKomunikimetState([]);
              refetchKomunikimet();
            } else {
              setStatus({
                ok: false,
                message:
                  "Ky veprim nuk mund të kryhet për momentin, ju lutem provoni përsëri.",
              });
            }
          }}
        >
          {({
            submitForm,
            isSubmitting,
            isValid,
            status,
            values,
            touched,
            errors,
            setFieldValue,
          }) => {
            // console.log(touched, errors);
            return (
              <Form>
                <p className="forma-aplikimit-inputet-labels">Përmbajtja *</p>
                <FastField name="permbajtja">
                  {(props) => (
                    <TextField
                      {...props}
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={7}
                      rowsMax={15}
                    />
                  )}
                </FastField>
                {/* <Divider style={{ margin: "2vh auto" }} /> */}
                <br />
                <br />
                <Field name="files">
                  {(props) => (
                    <MultiUpload {...props} teksti="Ngarko dokument" />
                  )}
                </Field>

                <Grid
                  direction="column"
                  alignItems="center"
                  container
                  style={{ marginTop: "32px", marginBottom: "15px" }}
                >
                  <Button
                    disableElevation
                    disableRipple
                    disableFocusRipple
                    variant="contained"
                    size="large"
                    style={{
                      backgroundColor:
                        !isValid || isSubmitting ? "" : "#CF0429",
                      color: !isValid || isSubmitting ? "" : "white",
                      textTransform: "none",
                      minWidth: "200px",
                      minHeight: "43px",
                    }}
                    disabled={!isValid || isSubmitting}
                    onClick={submitForm}
                  >
                    {isSubmitting ? (
                      <CircularProgress
                        style={{
                          color: "#545454",
                          width: "24px",
                          height: "24px",
                        }}
                      />
                    ) : (
                      "Dërgo"
                    )}
                  </Button>
                  {status?.ok === false && (
                    <p style={{ textAlign: "center" }}>{status?.message}</p>
                  )}
                  {!isValid && (
                    <p
                      style={{
                        textAlign: "center",
                        marginBottom: "0",
                        fontSize: "0.75rem",
                        color: "#f44336",
                        lineHeight: "1.66",
                        letterSpacing: "0.03333em",
                      }}
                    >
                      Ju lutem sigurohuni që i keni plotësuar të gjitha fushat e
                      detyrueshme.
                    </p>
                  )}
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};
