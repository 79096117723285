import { Button, Grid, CircularProgress } from "@material-ui/core";
import { FastField, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { logInUser } from "../actions/auth";
import { useUserStore } from "../context/user";
import { validation } from "../utils/validation";

export const LoginComponent = ({
  showLogin,
  setConfirmRegisterPopupStatus,
  setEmail,
  handleReset,
}) => {
  const { logIn } = useUserStore();
  return (
    <div style={{ display: showLogin ? "" : "none" }}>
      <div className="forma-aplikimit-inputet">
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={validation.logimi}
          onSubmit={async (values, { setStatus, setSubmitting }) => {
            setStatus();
            const { ok, res } = await logInUser({ values });

            if (ok) {
              const {
                user_id,
                email,
                user_roles,
                access_token,
                emri,
                mbiemri,
              } = res;
              if (user_roles !== "qytetar") {
                setStatus({ message: "Logohuni në panelin e plaformës." });
                return;
              }

              logIn({
                userId: user_id,
                email,
                role: user_roles,
                token: access_token,
                emri,
                mbiemri,
              });
            } else {
              if (res === "Enter OTP") {
                setEmail(values.email);
                setConfirmRegisterPopupStatus(true);
              } else {
                setStatus({
                  message:
                    "Ju lutem sigurohuni që fjalëkalimi të jetë i saktë dhe që kjo adresë email-i është regjistruar më parë.",
                });
              }
            }
          }}
        >
          {({ submitForm, isSubmitting, isValid, status }) => {
            return (
              <Form>
                <p className="forma-aplikimit-inputet-labels">
                  Adresa e-mail *
                </p>
                <FastField name="email">
                  {(props) => (
                    <TextField
                      {...props}
                      variant="outlined"
                      placeholder="adresa@domain.com"
                      fullWidth
                    />
                  )}
                </FastField>
                <p className="forma-aplikimit-inputet-labels">Fjalëkalimi *</p>
                <FastField name="password">
                  {(props) => (
                    <TextField
                      {...props}
                      type="password"
                      variant="outlined"
                      fullWidth
                      placeholder="Plotësoni fjalëkalimin"
                    />
                  )}
                </FastField>
                <p
                  style={{
                    marginBottom: 0,
                    marginTop: "5px",
                    textDecoration: "underline",
                    color: "#cf0429",
                    cursor: "pointer",
                  }}
                  onClick={handleReset}
                >
                  Kam harruar fjalëkalimin
                </p>
                <Grid
                  alignItems="center"
                  direction="column"
                  container
                  style={{ marginTop: "64px" }}
                >
                  <Button
                    disableElevation
                    disableRipple
                    disableFocusRipple
                    variant="contained"
                    size="large"
                    style={{
                      backgroundColor:
                        !isValid || isSubmitting ? "" : "#CF0429",
                      color: !isValid || isSubmitting ? "" : "white",
                      textTransform: "none",
                      minWidth: "300px",
                      maxWidth: "300px",
                      minHeight: "43px",
                    }}
                    disabled={!isValid || isSubmitting}
                    onClick={submitForm}
                  >
                    {isSubmitting ? (
                      <CircularProgress
                        style={{
                          color: "#545454",
                          width: "24px",
                          height: "24px",
                        }}
                      />
                    ) : (
                      "Identifikohu"
                    )}
                  </Button>
                  <p>{status?.message}</p>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};
