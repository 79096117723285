import { Select } from "formik-material-ui";
import { MenuItem } from "@material-ui/core";
import { useEffect, useState } from "react";
export const SelectShteti = (props) => {
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    setIsError(props?.meta?.touched && props?.meta?.error);
  }, [props?.meta?.error, props?.meta?.touched]);
  // if (props.field.name === "detajeEdukimiBachelor.shteti") {
  //   console.log(props);
  // }
  return (
    <>
      <Select
        variant="outlined"
        style={{
          boxShadow: isError ? "#ff0000 0px 0px 0px 1px" : "",
          // border: isError ? "red 1px solid" : "",
        }}
        color={isError ? "secondary" : "primary"}
        fullWidth
        {...props}
      >
        <MenuItem disableRipple defaultChecked value="empty">
          {props?.infoText?.default}
        </MenuItem>
        {props?.data?.status === "loading" ? (
          <MenuItem>Duke marre te dhenat</MenuItem>
        ) : props?.data?.status === "error" ? (
          <MenuItem>Ka ndodhur nje gabim</MenuItem>
        ) : (
          props?.data?.data?.map((sh) => (
            <MenuItem key={sh.id} disableRipple value={`${sh.id}`}>
              {sh.shteti}
            </MenuItem>
          ))
        )}
      </Select>
      {isError && (
        <p
          style={{ width: "100%" }}
          className="forma-aplikimit-inputet-errorText"
        >
          {props?.meta?.error}
        </p>
      )}
    </>
  );
};
