import create from "zustand";

export const useUserStore = create((set) => ({
  loggedInStatus: false,
  userId: "",
  email: "",
  role: "",
  token: "",
  emri: "",
  mbiemri: "",
  redirectPath: "",
  registerFinished: true,
  setRedirectPath: ({ redirectPath }) => set(() => ({ redirectPath })),
  logIn: ({ userId, email, role, token, emri, mbiemri }) =>
    set(() => {
      localStorage.setItem("loggedInStatus", true);
      localStorage.setItem("userId", userId);
      localStorage.setItem("email", email);
      localStorage.setItem("role", role);
      localStorage.setItem("token", token);
      localStorage.setItem("emri", emri);
      localStorage.setItem("mbiemri", mbiemri);
      return {
        loggedInStatus: true,
        userId,
        email,
        role,
        token,
        emri,
        mbiemri,
      };
    }),
  logOut: () =>
    set(() => {
      localStorage.removeItem("loggedInStatus");
      localStorage.removeItem("userId");
      localStorage.removeItem("email");
      localStorage.removeItem("role");
      localStorage.removeItem("token");
      localStorage.removeItem("emri");
      localStorage.removeItem("mbiemri");
      return {
        loggedInStatus: false,
        userId: "",
        email: "",
        role: "",
        token: "",
        emri: "",
        mbiemri: "",
      };
    }),
  checkIfLoggedIn: () =>
    set(() => {
      let loggedInStatus = localStorage.getItem("loggedInStatus");
      loggedInStatus = loggedInStatus === "true" ? true : false;
      const userId = localStorage.getItem("userId");
      const email = localStorage.getItem("email");
      const role = localStorage.getItem("role");
      const token = localStorage.getItem("token");
      const emri = localStorage.getItem("emri");
      const mbiemri = localStorage.getItem("mbiemri");
      return {
        loggedInStatus,
        userId,
        email,
        role,
        token,
        emri,
        mbiemri,
      };
    }),
  setRegisterFinished: ({ registerFinished }) =>
    set(() => ({ registerFinished })),
}));
