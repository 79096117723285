import { useEffect, useState } from "react";
import { MenuItem, Chip } from "@material-ui/core";
import { Select } from "formik-material-ui";

export const MultiSelect = (props) => {
  // console.log(props);
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    setIsError(
      props?.form?.errors[`${props.multiName}`] &&
        (props?.form?.touched[`${props.name}`] ||
          props?.form?.touched[`${props.multiName}`])
    );
  }, [props?.form?.errors, props?.form?.touched, props.multiName, props.name]);

  const onMultiSelectDelete = ({ id }) => {
    const withOutDeleted = props?.form?.values[`${props.multiName}`].filter(
      (f) => f.id !== id
    );
    props?.form?.setFieldValue(props.multiName, withOutDeleted);
  };

  const onMultiSelectAdd = ({ id, fusha }) => {
    props?.form?.setFieldTouched(props.multiName);
    const bool = props?.form?.values[`${props.multiName}`].filter(
      (f) => f.id === id
    );
    if (!bool.length) {
      props?.form?.setFieldValue(props.multiName, [
        ...props.form.values[`${props.multiName}`],
        { id, fusha },
      ]);
    }
  };

  return (
    <div>
      <Select
        variant="outlined"
        style={{
          boxShadow: isError ? "inset 0px 0px 0px 1px #f00" : "",
        }}
        color={isError ? "secondary" : "primary"}
        fullWidth
        {...props}
        onChange={(e) => {
          props?.form?.validateField(props.multiName);
        }}
      >
        <MenuItem disableRipple value="empty">
          {props?.form?.values[`${props.multiName}`].length === 0
            ? `${props?.infoText?.default}`
            : `${props?.infoText?.optional}`}
        </MenuItem>
        {props.data.status === "loading" ? (
          <MenuItem>Duke marrë të dhënat</MenuItem>
        ) : props.data.status === "error" ? (
          <MenuItem>Ka ndodhur një gabim</MenuItem>
        ) : (
          props.data.data.map((f) => (
            <MenuItem
              key={f.id}
              disableRipple
              value={`${f.id}`}
              onClick={(e) => {
                onMultiSelectAdd({ id: f.id, fusha: f.fusha });
              }}
            >
              {f.fusha}
            </MenuItem>
          ))
        )}
      </Select>
      <div style={{ display: "flex", flexWrap: "wrap", marginTop: "5px" }}>
        {props?.form?.values[`${props.multiName}`].length !== 0 &&
          props?.form?.values[`${props.multiName}`].map((f) => (
            <Chip
              style={{
                margin: "5px",
                backgroundColor: "#cf0429",
              }}
              key={f.id}
              label={f.fusha}
              onDelete={() => onMultiSelectDelete({ id: f.id, props })}
              color="secondary"
            />
          ))}
      </div>
      {isError && (
        <p
          style={{ width: "100%" }}
          className="forma-aplikimit-inputet-errorText"
        >
          {props?.form?.errors[`${props.multiName}`]}
        </p>
      )}
    </div>
  );
};
