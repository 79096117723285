import { gql } from "graphql-request";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  useMediaQuery,
} from "@material-ui/core";
import { useQuery } from "react-query";
import { useAuthorizedGraphqlClient } from "../utils/useAuthorizedGraphqlClient";
// import { SingleKontribut } from "../components/SingleKontribut";
import { AddCircleOutline, Error } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { useUserStore } from "../context/user";
// import { fakeKontributAndKomunikim } from "../utils/fakeData";
import { SingleKomunikim } from "../components/SingleKomunikim";
import { DetajetEQytetarit } from "../components/DetajetEQytetarit";
import { useCallback, useEffect, useRef, useState } from "react";
import { KomunikimIRiPopup } from "../components/KomunikimIRiPopup";
import { constants } from "../utils/constants";
// import { useSnackbar } from "notistack";

export const Kontributet = () => {
  const token = useUserStore();
  const client = useAuthorizedGraphqlClient({ token });
  const {
    data: kontributi,
    isLoading: isLoadingKontribute,
    isError: isErrorKontribute,
  } = useGetKontributet({ client });
  let kontributId;
  try {
    kontributId = kontributi[0].id;
  } catch {}
  const [skip, setSkip] = useState(0);
  const {
    data: komunikimet,
    isLoading: isLoadingKomunikimet,
    isError: isErrorKomunikimet,
    refetch: refetchKomunikimet,
    status: statusKomunikimet,
  } = useGetKomunikimet({
    client,
    kontributId,
    limit: constants.komunikimetLimit,
    skip,
  });

  const matches = useMediaQuery("(max-width:600px)");

  const history = useHistory();
  const handleCreateKontribut = () => {
    history.push("/kontributet/krijo");
    // setKontributPopUp(true);
  };

  const [komunikimPopup, setKomunikimPopup] = useState(false);

  // infinite scrolling
  const [komunikimetState, setKomunikimetState] = useState([]);
  useEffect(() => {
    if (
      !isLoadingKomunikimet &&
      !isErrorKomunikimet &&
      statusKomunikimet === "success"
    ) {
      setKomunikimetState([...komunikimetState, ...komunikimet]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    komunikimet,
    isErrorKomunikimet,
    isLoadingKomunikimet,
    statusKomunikimet,
    // skip,
  ]);
  const increaseSkip = () => {
    setSkip(skip + constants.komunikimetLimit);
  };
  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (isLoadingKomunikimet) {
        return;
      }
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(
        (entries) => {
          if (
            entries[0].isIntersecting &&
            komunikimet.length === constants.komunikimetLimit &&
            statusKomunikimet === "success"
          ) {
            increaseSkip();
          }
        },
        { threshold: 1.0 }
      );
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoadingKomunikimet, komunikimet]
  );
  return (
    <div>
      <div style={{ marginTop: "5vh" }}>
        <div>
          {isLoadingKontribute ? (
            <Grid container direction="column" alignItems="center">
              <CircularProgress size="3rem" color="secondary" />
              <p>Duke marrë të dhënat</p>
            </Grid>
          ) : isErrorKontribute ? (
            <Grid container direction="column" alignItems="center">
              <Error fontSize="large" color="secondary" />
              <p>Një gabim ka ndodhur, provoni përsëri.</p>
            </Grid>
          ) : kontributi.length === 0 ? (
            <div
              style={{ maxWidth: matches ? "80vw" : "50vw", margin: "0 auto" }}
            >
              <Grid
                alignItems="center"
                container
                style={{
                  flexDirection: "column",
                  border: "1px gray dashed",
                  borderRadius: "15px",
                  padding: "5vw",
                  color: "#545454",
                }}
              >
                <IconButton
                  style={{
                    marginBottom: "2vw",
                    fontSize: matches ? "12vw" : "4vw",
                    color: "#CF0429",
                  }}
                  onClick={handleCreateKontribut}
                >
                  <AddCircleOutline fontSize="inherit" />
                </IconButton>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: matches ? "18px" : "24px",
                  }}
                >
                  Ju ende nuk keni dërguar kontributin tuaj.
                </p>
              </Grid>
            </div>
          ) : (
            <>
              <DetajetEQytetarit
                kontributi={kontributi[0]}
                qytetari={kontributi[0]?.perdoruesit}
              />
              <div style={{ marginBottom: "5vh" }}>
                {
                  // isLoadingKomunikimet ? (
                  //   <Grid container direction="column" alignItems="center">
                  //     <CircularProgress size="3rem" color="secondary" />
                  //     <p>Duke marrë të dhënat</p>
                  //   </Grid>
                  // ) : isErrorKomunikimet ? (
                  //   <Grid container direction="column" alignItems="center">
                  //     <Error fontSize="large" color="secondary" />
                  //     <p>Një gabim ka ndodhur, provoni përsëri.</p>
                  //   </Grid>
                  // ) : (
                  //  : komunikimet.length === 0 ? (
                  //   <div
                  //     style={{
                  //       maxWidth: matches ? "80vw" : "50vw",
                  //       margin: "0 auto",
                  //     }}
                  //   >
                  //     <Grid
                  //       alignItems="center"
                  //       container
                  //       style={{
                  //         flexDirection: "column",
                  //         border: "1px gray dashed",
                  //         borderRadius: "15px",
                  //         padding: "5vw",
                  //         color: "#545454",
                  //       }}
                  //     >
                  //       <p
                  //         style={{
                  //           textAlign: "center",
                  //           fontSize: matches ? "16px" : "18px",
                  //         }}
                  //       >
                  //         Kontributi juaj u dërgua me sukses, do të njoftoheni me
                  //         email kur t'ju vi një përgjigje në platformë.
                  //       </p>
                  //     </Grid>
                  //   </div>
                  // )
                  <Grid>
                    <Grid container justify="flex-end">
                      <Button
                        disableElevation
                        disableFocusRipple
                        disableRipple
                        disableTouchRipple
                        variant="contained"
                        style={{
                          textTransform: "none",
                          backgroundColor: "#CF0429",
                          color: "white",
                        }}
                        onClick={() => {
                          setKomunikimPopup(true);
                        }}
                      >
                        Dërgo mesazh
                      </Button>
                    </Grid>
                    <Grid>
                      {komunikimetState.map((k, i) => (
                        <SingleKomunikim
                          key={k?.id}
                          komunikim={k}
                          // showReply={i === 0 ? true : false}
                          setKomunikimPopup={setKomunikimPopup}
                          refetchKomunikimet={refetchKomunikimet}
                          lastElementRef={
                            komunikimetState.length === i + 1
                              ? lastElementRef
                              : null
                          }
                        />
                      ))}
                      {isLoadingKomunikimet ? (
                        <Grid
                          style={{ marginTop: "15px" }}
                          container
                          direction="column"
                          alignItems="center"
                        >
                          <CircularProgress size="3rem" color="secondary" />
                          <p>Duke marrë të dhënat</p>
                        </Grid>
                      ) : //  : canIncreaseSkip ? (
                      //   <Grid
                      //     style={{ marginTop: "15px" }}
                      //     container
                      //     direction="column"
                      //     alignItems="center"
                      //   >
                      //     <Button variant="contained" onClick={increaseSkip}>
                      //       Show more
                      //     </Button>
                      //   </Grid>
                      // )
                      null}
                    </Grid>
                  </Grid>
                }
              </div>

              <KomunikimIRiPopup
                komunikimPopup={komunikimPopup}
                setKomunikimPopup={setKomunikimPopup}
                refetchKomunikimet={refetchKomunikimet}
                setKomunikimetState={setKomunikimetState}
                kontributi={kontributi}
                setSkip={setSkip}
                // setCanIncreaseSkip={setCanIncreaseSkip}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const useGetKontributet = ({ client }) => {
  return useQuery("getKontributet", async () => {
    const data = (await client).request(
      gql`
        query {
          kontributet(order_by: { created_at: desc }, limit: 1) {
            perdoruesit {
              id
              emri
              mbiemri
            }
            id
            case_nr
            shtetet {
              shteti
            }
            nivelet_e_edukimit {
              info
            }
            profesioni
            vite_eksperience
            kthimi
            menyrat_e_angazhimit {
              info
            }
            eventet {
              status_kontributet {
                info
              }
              ora_takimit
            }
            files {
              id
              name
            }
          }
        }
      `
    );

    const { kontributet } = await data;

    return kontributet;
  });
};

const useGetKomunikimet = ({ client, kontributId, limit, skip }) => {
  return useQuery(
    ["getKomunikimetByKontributId", kontributId, limit, skip],
    async () => {
      const data = (await client).request(
        gql`
        query {
          komunikimet(
            where: { kontribut_id: { _eq: ${kontributId} } }
            order_by: { created_at: desc }
            limit: ${limit}
            offset: ${skip}
          ) {
            id
            tekst
            seen_by_qytetar
            created_at
            perdoruesit {
              id
              institucionet {
                institucioni
              }
            }
            files {
              id
              name
            }
          }
        }
      `
      );

      const { komunikimet } = await data;

      return komunikimet;
    },
    { enabled: !!kontributId, cacheTime: 0 }
  );
};
