import { useEffect, useRef, useState } from "react";
import { Button, Tooltip, ClickAwayListener } from "@material-ui/core";
import { UploadIcon } from "../assets/UploadIcon";
import { InfoIcon } from "../assets/InfoIcon";

export const UploadField = (props) => {
  // console.log({ props });
  const [openTooltip, setOpenTooltip] = useState(false);
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    setIsError(props?.meta?.error && props?.meta?.touched);
  }, [props?.meta?.error, props?.meta?.touched]);
  const hiddenInput = useRef();
  const input = useRef();
  const newProps = { ...props };
  const shorter = props?.widthcontrol ? { width: "100%" } : {};
  newProps.className = "";
  return (
    <div className={props.className}>
      <div
        className="forma-aplikimit-inputet-upload-input-me-info"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          ...shorter,
        }}
      >
        <div
          ref={input}
          style={{
            display: "flex",
            width: "100%",
            padding: "5px 0",
            cursor: "pointer",
            borderRadius: "4px",
            border: `1px ${isError ? "#f55b85" : "#B7B7B7"}  solid`,
          }}
          onClick={() => {
            hiddenInput.current.click();
            props.form.setFieldTouched(`${props.field.name}`);
          }}
          {...newProps}
        >
          <p
            style={{
              width: "90%",
              margin: "13px 10px",
              color: props?.field?.value?.name ? "#545454" : "#9D9B9B",
            }}
          >
            {props?.field?.value?.name
              ? props?.field?.value?.name
              : props.teksti}
          </p>
          <Button
            placeholder="Hidh"
            disableRipple
            disableElevation
            variant="contained"
            style={{ margin: "3px 5px" }}
          >
            <UploadIcon />
          </Button>
          <input
            type="file"
            accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/jpeg,.pdf"
            ref={hiddenInput}
            style={{ display: "none" }}
            onChange={(e) => {
              props?.form?.setFieldValue(
                `${props?.field?.name}`,
                e.target.files[0]
              );
            }}
            {...props}
          />
        </div>
        <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
          <Tooltip
            open={openTooltip}
            // onClose={() => setOpenTooltip(false)}
            disableFocusListener
            disableHoverListener
            placement="top"
            title={"Madhesia e dokumentave nuk duhet te kaloje 15MB. \n Formati i skedareve duhet te jete i tipit doc / docx / pdf / jpg / jpeg"}
          >
            <div
              onClick={() => setOpenTooltip(true)}
              style={{ marginTop: "9px" }}
            >
              <InfoIcon style={{ marginLeft: "7px" }} />
            </div>
          </Tooltip>
        </ClickAwayListener>
      </div>
      {isError && (
        <p className="forma-aplikimit-inputet-errorText">
          {props?.meta?.error}
        </p>
      )}
    </div>
  );
};
