import { gql } from "graphql-request";
import { fileUpload } from "./fileUpload";

export const createKomunikim = async ({
  client,
  values,
  kontributId,
  token,
}) => {
  try {
    const { permbajtja, files } = values;
    const data = (await client).request(
      gql`
        mutation CreateKomunikim($tekst:String) {
          insert_komunikimet_one(
            object: { kontribut_id: ${kontributId}, tekst: $tekst, seen_by_qytetar: true }
          ) {
            id
          }
        }
      `,
      { tekst: permbajtja }
    );
    const {
      insert_komunikimet_one: { id },
    } = await data;
    if (!id) {
      return { ok: false };
    }
    if (files.length === 0) {
      return { ok: true };
    } else {
      await Promise.all(
        files.map(async (f) => {
          let ok = false;
          let retry = 3;
          do {
            const up = await fileUpload({
              fileName: f.name,
              fileValue: f,
              komunikimId: id,
              token,
            });
            if (up?.id) {
              ok = true;
            } else {
              retry--;
            }
          } while (!ok && retry);
        })
      );
      return { ok: true };
    }
  } catch (e) {
    console.log({ e });
    return { ok: false };
  }
};

export const setSeenRequest = async ({ client, komunikimId }) => {
  try {
    const data = (await client).request(
      gql`
      mutation {
        update_komunikimet_by_pk(
          _set: { seen_by_qytetar: true }
          pk_columns: { id: ${komunikimId} }
        ) {
          id
        }
      }
    `
    );
    const { update_komunikimet_by_pk: id } = await data;
    if (id) {
      return { ok: true };
    } else {
      return { ok: false };
    }
  } catch (e) {
    console.log({ e });
    return { ok: false };
  }
};
