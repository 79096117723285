import { gql } from "graphql-request";
import { fileUpload } from "./fileUpload";

export const createKontribut = async ({ client, values, userId, token }) => {
  let kontributi = {
    kthimi: values.kthimi.dbValue,
    menyraAngazhimit: values.menyraAngazhimit.dbValue,
    perdorues_id: userId,
  };
  try {
    let ok = false;
    do {
      const leterMotivimi = await fileUpload({
        fileName: values.leterMotivimi.name,
        fileValue: values.leterMotivimi,
        token,
      });
      if (leterMotivimi?.ok) {
        ok = true;
        kontributi.leterMotivimi = leterMotivimi.id;
      }
    } while (!ok);

    ok = false;
    do {
      const leterMotivimi2 = await fileUpload({
        fileName: values.leterMotivimi2.name,
        fileValue: values.leterMotivimi2,
        token,
      });
      if (leterMotivimi2?.ok) {
        ok = true;
        kontributi.leterMotivimi2 = leterMotivimi2.id;
      }
    } while (!ok);

    const data = (await client).request(
      gql`
   mutation {
  insert_Kontributet_one(
    object: {
      perdorues_id: "${kontributi.perdorues_id}", 
      kontributi_juaj: ${kontributi.kthimi}
      menyra_angazhimit: ${kontributi.menyraAngazhimit}
      leter_interesi_file_id: "${kontributi.leterMotivimi}", 
      leter_interesi_2_file_id: "${kontributi.leterMotivimi2}"
      }) {
    id
  }
}
  `
    );

    const {
      insert_Kontributet_one: { id: kontributId },
    } = await data;

    values.fushatEZgjedhura.map(async (k) => {
      (await client).request(
        gql`
          mutation {
          insert_KontributetFushat_one(
              object: {
                fusha_id: "${k.id}", 
                kontribut_id: "${kontributId}"
                }
              ) {
              kontribut_id
            }
          }
  `
      );
    });
  } catch (e) {
    return { ok: false };
  }

  return { ok: true };
};

export const createFullKontribut = async ({
  client,
  values,
  userId,
  token,
  emri,
  mbiemri,
}) => {
  try {
    const { gjinia, nrTelefoni } = values;

    const data = (await client).request(
      gql`
        mutation {
          update_perdoruesit_by_pk(
            pk_columns: { id: ${userId} }
            _set: { gjinia: ${gjinia.dbValue}, tel: "${nrTelefoni}" }
          ) {
            id
          }
        }
      `
    );
    const {
      update_perdoruesit_by_pk: { id },
    } = await data;
    if (!id) {
      return { ok: false };
    }
  } catch (e) {
    console.log({ e });
    return { ok: false };
  }
  const {
    shteti,
    nivelEdukimi,
    detajeEdukimiBachelor,
    profesioni,
    viteEksperience,
    kthimi,
    menyraAngazhimit,
    fushatEZgjedhura,
    leterMotivimi,
    leterMotivimi2,
  } = values;
  const detajetMaster =
    nivelEdukimi?.id > 1
      ? `master_fusha_studimit: "${values?.detajeEdukimiMaster?.fushaStudimit}"
      master_universiteti: "${values?.detajeEdukimiMaster?.universiteti}"
      master_shteti: ${values?.detajeEdukimiMaster?.shteti}
      `
      : "";
  const detajetDoktorature =
    nivelEdukimi?.id > 3
      ? `doktorature_fusha_studimit: "${values?.detajeEdukimiDoktorature?.fushaStudimit}"
      doktorature_universiteti: "${values?.detajeEdukimiDoktorature?.universiteti}"
      doktorature_shteti: ${values?.detajeEdukimiDoktorature?.shteti}
      `
      : "";

  let filledId = `${userId}`;
  while (filledId.length < 4) {
    filledId = "0" + filledId;
  }
  const caseNr = emri.charAt(0) + mbiemri.charAt(0) + filledId;

  const kthimiii =
    kthimi === "empty" || shteti === "74"
      ? ""
      : `
  kthimi: ${kthimi.dbValue}
  `;

  try {
    const data = (await client).request(
      gql`
        mutation {
          insert_kontributet_one(
            object: {
              case_nr: "${caseNr}"
              perdorues_id: ${userId}
              shteti_id: ${shteti}
              nivel_edukimi: ${nivelEdukimi.dbValue}
              bachelor_fusha_studimit: "${detajeEdukimiBachelor.fushaStudimit}"
              bachelor_universiteti: "${detajeEdukimiBachelor.universiteti}"
              bachelor_shteti: ${detajeEdukimiBachelor.shteti}
              profesioni: "${profesioni}"
              vite_eksperience: ${viteEksperience.dbValue}
              ${kthimiii}
              menyra_angazhimit: ${menyraAngazhimit.dbValue}
              ${detajetMaster}
              ${detajetDoktorature}
            }
          ) {
            id
          }
        }
      `
    );

    const {
      insert_kontributet_one: { id: kontributId },
    } = await data;

    fushatEZgjedhura.map(async (f) => {
      (await client).request(
        gql`
            mutation {
              insert_kontributet_fushat_e_interesit_one(
                object: { fusha_id: ${f.id}, kontribut_id: ${kontributId} }
              ) {
                kontribut_id
              }
            }
          `
      );
    });

    const defaultEvent = (await client).request(
      gql`
          mutation {
            insert_eventet_one(object: { kontribut_id: ${kontributId} }) {
              id
            }
          }
        `
    );
    const {
      insert_eventet_one: { id: eventId },
    } = await defaultEvent;

    if (!eventId) {
      return { ok: false };
    }

    const timeout = 3000;
    let ok = false;
    let retry = 3;
    do {
      const upOne = await fileUpload({
        fileName: leterMotivimi.name,
        fileValue: leterMotivimi,
        kontributId,
        token,
      });
      if (upOne?.ok) {
        ok = true;
      } else {
        setTimeout(() => {
          console.log("tu prit");
        }, timeout);
      }
      retry--;
    } while (!ok && retry);

    ok = false;
    retry = 3;
    do {
      const upTwo = await fileUpload({
        fileName: leterMotivimi2.name,
        fileValue: leterMotivimi2,
        kontributId,
        token,
      });
      if (upTwo?.ok) {
        ok = true;
      } else {
        setTimeout(() => {
          console.log("tu prit");
        }, timeout);
      }
      retry--;
    } while (!ok && retry);
    
  } catch (e) {
    console.log({ e });
    return { ok: false };
  }

  return { ok: true };
};
