import { SvgIcon } from "@material-ui/core";

export const UploadIcon = (props) => (
  <SvgIcon viewBox="0 0 20 20" style={{ margin: "0 auto" }} {...props}>
    <path
      d="M13.2016 16.173H6.71875C6.53227 16.173 6.35342 16.0989 6.22156 15.9671C6.0897 15.8352 6.01562 15.6564 6.01562 15.4699V9.05318H3.38172C3.2457 9.05323 3.11258 9.01381 2.99851 8.93973C2.88444 8.86564 2.79431 8.76005 2.73904 8.63577C2.68377 8.51148 2.66573 8.37384 2.68712 8.23951C2.70851 8.10518 2.7684 7.97994 2.85953 7.87897L9.4375 0.582871C9.50343 0.509679 9.58401 0.451157 9.674 0.411099C9.764 0.371041 9.86141 0.350342 9.95992 0.350342C10.0584 0.350342 10.1558 0.371041 10.2458 0.411099C10.3358 0.451157 10.4164 0.509679 10.4823 0.582871L17.0594 7.87897C17.1505 7.97994 17.2104 8.10518 17.2318 8.23951C17.2532 8.37384 17.2351 8.51148 17.1799 8.63577C17.1246 8.76005 17.0345 8.86564 16.9204 8.93973C16.8063 9.01381 16.6732 9.05323 16.5372 9.05318H13.9047V15.4699C13.9047 15.6564 13.8306 15.8352 13.6987 15.9671C13.5669 16.0989 13.388 16.173 13.2016 16.173ZM7.42187 14.7668H12.4984V8.35006C12.4984 8.16358 12.5725 7.98474 12.7044 7.85288C12.8362 7.72101 13.0151 7.64693 13.2016 7.64693H14.9566L9.95922 2.10397L4.96234 7.64693H6.71875C6.90523 7.64693 7.08407 7.72101 7.21593 7.85288C7.34779 7.98474 7.42187 8.16358 7.42187 8.35006V14.7668Z"
      fill="#595959"
    />
    <path
      d="M16.3206 19.1946H3.59829C2.78675 19.1938 2.00869 18.871 1.43484 18.2972C0.860992 17.7233 0.538222 16.9452 0.537354 16.1337V14.3445C0.537354 14.158 0.611433 13.9792 0.743294 13.8473C0.875156 13.7154 1.054 13.6414 1.24048 13.6414C1.42696 13.6414 1.6058 13.7154 1.73766 13.8473C1.86952 13.9792 1.9436 14.158 1.9436 14.3445V16.1337C1.9441 16.5724 2.11859 16.993 2.4288 17.3032C2.73901 17.6134 3.15959 17.7879 3.59829 17.7884H16.3206C16.7593 17.7879 17.1798 17.6134 17.49 17.3032C17.8001 16.9929 17.9745 16.5724 17.9749 16.1337V14.3445C17.9749 14.158 18.0489 13.9792 18.1808 13.8473C18.3127 13.7154 18.4915 13.6414 18.678 13.6414C18.8645 13.6414 19.0433 13.7154 19.1752 13.8473C19.307 13.9792 19.3811 14.158 19.3811 14.3445V16.1337C19.3802 16.9452 19.0575 17.7232 18.4838 18.297C17.91 18.8708 17.1321 19.1936 16.3206 19.1946Z"
      fill="#595959"
    />
  </SvgIcon>
);
