import "./FormaAplikimit.scss";

import { Button, CircularProgress, Grid } from "@material-ui/core";
import { UploadField } from "./UploadField";
import { Field, Formik, Form, FastField } from "formik";
import { TextField } from "formik-material-ui";
import { validation } from "../utils/validation";

import { constants } from "../utils/constants";

import { useAuthorizedGraphqlClient } from "../utils/useAuthorizedGraphqlClient";
import {
  // useMutation,
  useQuery,
} from "react-query";
import { gql } from "graphql-request";
// import { saveForm, saveUserSetup } from "../utils/saveFormBeforeLogInOrSignUp";
import { Link, useHistory } from "react-router-dom";
import { useUserStore } from "../context/user";
import { MultiSelect } from "./MultiSelect";
import { SelectShteti } from "./SelectShteti";
import { SelectMeTeDhenaStatike } from "./SelectMeTeDhenaStatike";
import { DetajetEEdukimit } from "./DetajetEEdukimit";
// import { useNoAuthClient } from "../utils/useNoAuthClient";
import { createFullKontribut } from "../actions/kontribut";

export const FormaAplikimit = () => {
  const history = useHistory();
  const { token, userId, emri, mbiemri } = useUserStore();

  //   const history = useHistory();
  //   const { token, userId } = useUserStore();

  // const [loginPopupStatus, setLoginPopupStatus] = useState(false);
  const client = useAuthorizedGraphqlClient();
  // const noAuthClient = useNoAuthClient();
  const shtetet = useGetShtetet({
    client,
  });
  const fushat = useGetFushat({ client });

  return (
    // <div className="forma-aplikimit">
    //   <p className="forma-aplikimit-tekst-kryesor">Formulari i aplikimit</p>
    <div className="forma-aplikimit-inputet">
      <Formik
        initialValues={{
          // emri: "",
          // mbiemri: "",
          gjinia: "empty",
          nrTelefoni: "",
          // email: "",
          shteti: "empty",
          // nenshtetesia: "empty",
          nivelEdukimi: "empty",
          detajeEdukimiBachelor: {
            fushaStudimit: "",
            universiteti: "",
            shteti: "empty",
          },
          detajeEdukimiMaster: {
            fushaStudimit: "",
            universiteti: "",
            shteti: "empty",
          },
          detajeEdukimiDoktorature: {
            fushaStudimit: "",
            universiteti: "",
            shteti: "empty",
          },
          profesioni: "",
          viteEksperience: "empty",
          kthimi: "empty",
          menyraAngazhimit: "empty",
          fushaInteresit: "empty",
          fushatEZgjedhura: [],
          leterMotivimi: "",
          leterMotivimi2: "",
        }}
        validationSchema={validation.formularAplikimi}
        onSubmit={async (values, { setStatus, setSubmitting }) => {
          // setSubmitting(true);

          // setTimeout(() => {
          //   console.log(values);
          //   setSubmitting(false);
          // }, 3000);
          const { ok } = await createFullKontribut({
            client,
            token,
            userId,
            values,
            emri,
            mbiemri,
          });
          if (ok) {
            history.push("/kontributet");
            // setStatus({
            //   ok: true,
            //   message: "Kontributi juaj u dërgua. Për ta parë atë kliko",
            // });
          } else {
            setStatus({
              ok: false,
              message:
                "Ky veprim nuk mund të kryhet për momentin, ju lutem provoni përsëri.",
            });
          }
        }}
      >
        {({ submitForm, isSubmitting, isValid, status, values, errors }) => {
          return (
            <Form>
              <p className="forma-aplikimit-inputet-labels">Gjinia *</p>
              <FastField name="gjinia">
                {(props) => (
                  <SelectMeTeDhenaStatike
                    name="gjinia"
                    data={constants.gjiniteAlt}
                    infoText={{ default: "Përcaktoni gjininë" }}
                    {...props}
                  />
                )}
              </FastField>
              <p className="forma-aplikimit-inputet-labels">Nr. Telefoni *</p>
              <FastField name="nrTelefoni">
                {(props) => (
                  <TextField
                    {...props}
                    variant="outlined"
                    fullWidth
                    placeholder="+355 67 33333333"
                  />
                )}
              </FastField>
              <p className="forma-aplikimit-inputet-labels">
                Shteti ku jetoni *
              </p>
              <Field name="shteti">
                {(props) => (
                  <SelectShteti
                    infoText={{ default: "Zgjidhni shtetin" }}
                    data={shtetet}
                    name="shteti"
                    {...props}
                  />
                )}
              </Field>
              <p className="forma-aplikimit-inputet-labels">
                Cili është niveli më i lartë i arsimimit që keni kryer? *
              </p>
              <FastField name="nivelEdukimi">
                {(props) => (
                  <SelectMeTeDhenaStatike
                    name="nivelEdukimi"
                    data={constants.nivelEdukimiAlt}
                    infoText={{
                      default:
                        "Zgjidhni nivelin më të lartë të arsimimit që keni përfunduar",
                    }}
                    {...props}
                  />
                )}
              </FastField>
              <div
                style={{
                  maxWidth: "80%",
                  margin: "0 auto",
                  marginTop: values?.nivelEdukimi?.id > 0 ? "63px" : "0",
                  marginBottom: values?.nivelEdukimi?.id > 0 ? "63px" : "0",
                }}
              >
                {values?.nivelEdukimi?.id > 0 && (
                  <>
                    <p className="forma-aplikimit-inputet-labels">
                      Plotësoni detajet e edukimit:
                    </p>
                    <DetajetEEdukimit
                      name="detajeEdukimiBachelor"
                      infoText={{
                        fushaStudimit: "shembull: Inxhinieri Informatike",
                        universiteti: "shembull: La Sapienza",
                      }}
                      data={shtetet}
                      niveliEdukimitEmer="Bachelor"
                    />
                    {/* <<<<<<< larg_veshve */}
                  </>
                )}
                {values?.nivelEdukimi?.id > 1 && (
                  <DetajetEEdukimit
                    name="detajeEdukimiMaster"
                    infoText={{
                      fushaStudimit: "shembull: Regjistra të shpërndarë",
                      universiteti: "shembull: La Sapienza",
                    }}
                    data={shtetet}
                    niveliEdukimitEmer="Master"
                  />
                )}
                {values?.nivelEdukimi?.id > 3 && (
                  <DetajetEEdukimit
                    name="detajeEdukimiDoktorature"
                    infoText={{
                      fushaStudimit: "shembull: Inxhinieri Informatike",
                      universiteti: "shembull: La Sapienza",
                    }}
                    data={shtetet}
                    niveliEdukimitEmer="Doktoraturë"
                  />
                )}
              </div>
              <p className="forma-aplikimit-inputet-labels">
                Cfarë profesioni kryeni aktualisht? *
              </p>
              <FastField name="profesioni">
                {(props) => (
                  <TextField
                    {...props}
                    variant="outlined"
                    placeholder="Plotësoni profesionin"
                    fullWidth
                  />
                )}
              </FastField>
              <p className="forma-aplikimit-inputet-labels">
                Sa vite eksperiencë keni në këtë profesion? *
              </p>
              <FastField name="viteEksperience">
                {(props) => (
                  <SelectMeTeDhenaStatike
                    name="viteEksperience"
                    data={constants.viteEksperienceAlt}
                    infoText={{
                      default: "Zgjidhni numrin e viteve të eksperincës",
                    }}
                    {...props}
                  />
                )}
              </FastField>
              {values.shteti !== "74" && (
                <>
                  <p className="forma-aplikimit-inputet-labels">
                    Dëshironi të jepni kontributin tuaj: *
                  </p>
                  <Field name="kthimi">
                    {(props) => (
                      <SelectMeTeDhenaStatike
                        name="kthimi"
                        data={constants.deshiraKontributitAlt}
                        infoText={{
                          default: "Zgjidhni një nga alternativat",
                        }}
                        {...props}
                      />
                    )}
                  </Field>
                </>
              )}

              <div style={{ maxWidth: "80%", margin: "62px auto" }}>
                <p className="forma-aplikimit-inputet-labels">
                  Mënyra e angazhimit: *
                </p>
                <Field name="menyraAngazhimit">
                  {(props) => (
                    <SelectMeTeDhenaStatike
                      name="menyraAngazhimit"
                      data={
                        values.kthimi.id === 1
                          ? constants.menyraAngazhimitAlt.filter(
                              (a) => a.id !== 1
                            )
                          : constants.menyraAngazhimitAlt
                      }
                      infoText={{
                        default: "Zgjidhni një nga alternativat",
                      }}
                      {...props}
                    />
                  )}
                </Field>
              </div>
              <p className="forma-aplikimit-inputet-labels">
                Fusha e interesit *
              </p>
              <Field name="fushaInteresit">
                {(props) => (
                  <MultiSelect
                    name="fushatEInteresit"
                    multiName="fushatEZgjedhura"
                    data={fushat}
                    infoText={{
                      default: "Zgjidhni fushën e kontributit tuaj",
                      optional: "Mund të zgjidhni një fushë tjetër",
                    }}
                    {...props}
                  />
                )}
              </Field>
              <Grid
                container
                style={{ marginTop: "30px" }}
                justify="space-between"
              >
                <FastField name="leterMotivimi">
                  {(props) => (
                    <UploadField
                      {...props}
                      widthcontrol="true"
                      className="forma-aplikimit-inputet-upload"
                      teksti="Ngarkoni letër motivimi *"
                    />
                  )}
                </FastField>
                <FastField name="leterMotivimi2">
                  {(props) => (
                    <UploadField
                      {...props}
                      widthcontrol="true"
                      className="forma-aplikimit-inputet-upload"
                      teksti="Ngarkoni CV *"
                    />
                  )}
                </FastField>
              </Grid>
              <Grid
                direction="column"
                alignItems="center"
                container
                style={{ marginTop: "64px" }}
              >
                <Button
                  disableElevation
                  disableRipple
                  disableFocusRipple
                  variant="contained"
                  size="large"
                  style={{
                    backgroundColor: !isValid || isSubmitting ? "" : "#CF0429",
                    color: !isValid ? "" : "white",
                    textTransform: "none",
                    minWidth: "300px",
                    minHeight: "43px",
                  }}
                  disabled={!isValid || isSubmitting}
                  onClick={submitForm}
                >
                  {isSubmitting ? (
                    <CircularProgress
                      style={{
                        color: "#545454",
                        width: "24px",
                        height: "24px",
                      }}
                    />
                  ) : (
                    "Jepni kontributin tuaj"
                  )}
                </Button>
                {status?.ok === false && (
                  <p style={{ textAlign: "center" }}>{status?.message}</p>
                )}
                {status?.ok === true && (
                  <p style={{ textAlign: "center" }}>
                    {status?.message} <Link to="/kontributet">këtu</Link>
                  </p>
                )}
                {!isValid && (
                  <p
                    style={{
                      textAlign: "center",
                      marginBottom: "0",
                      fontSize: "0.75rem",
                      color: "#f44336",
                      lineHeight: "1.66",
                      letterSpacing: "0.03333em",
                    }}
                  >
                    Ju lutem sigurohuni që i keni plotësuar të gjitha fushat e
                    detyrueshme.
                  </p>
                )}
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export const useGetShtetet = ({ client }) => {
  return useQuery("shtetet", async () => {
    const data = (await client).request(
      gql`
        query {
          shtetet(order_by: { shteti: asc }) {
            id
            shteti
          }
        }
      `
    );

    const { shtetet } = await data;
    return shtetet;
  });
};

export const useGetFushat = ({ client }) => {
  return useQuery("fushat", async () => {
    const data = (await client).request(
      gql`
        query {
          fushat_e_interesit(order_by: { fusha: asc }) {
            id
            fusha
          }
        }
      `
    );

    const { fushat_e_interesit } = await data;
    return fushat_e_interesit;
  });
};
