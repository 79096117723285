import { Button, Grid, CircularProgress } from "@material-ui/core";
import { FastField, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { resendOtpCode, resetPassword } from "../actions/auth";
import { useUserStore } from "../context/user";
import { validation } from "../utils/validation";

export const ResetPasswordComponent = ({ showResetPassword }) => {
  const { logIn } = useUserStore();
  return (
    <div style={{ display: showResetPassword ? "" : "none" }}>
      <div className="forma-aplikimit-inputet">
        <Formik
          initialValues={{
            step: 1,
            email: "",
            otpCode: "",
            newPassword: "",
            confirmNewPassword: "",
          }}
          validationSchema={validation.resetPassword}
          onSubmit={async (
            values,
            { setStatus, setFieldValue, setTouched }
          ) => {
            // eslint-disable-next-line default-case
            switch (values.step) {
              case 1:
                const { ok } = await resendOtpCode({ values });
                if (ok) {
                  setFieldValue("step", 2);
                  setStatus({});
                } else {
                  setStatus({
                    ok: false,
                    message:
                      "Ky veprim nuk mund të kryhet për momentin, ju lutem provoni përsëri.",
                  });
                }
                setTouched({ email: true });
                break;
              case 2:
                const { ok: okReset, res } = await resetPassword({ values });
                if (okReset) {
                  const {
                    user_id,
                    email,
                    user_roles,
                    access_token,
                    emri,
                    mbiemri,
                  } = res;
                  if (user_roles !== "qytetar") {
                    setStatus({ message: "Logohuni në panelin e plaformës." });
                    return;
                  }

                  logIn({
                    userId: user_id,
                    email,
                    role: user_roles,
                    token: access_token,
                    emri,
                    mbiemri,
                  });
                } else {
                  setStatus({
                    ok: false,
                    message:
                      "Ky veprim nuk mund të kryhet për momentin, ju lutem provoni përsëri.",
                  });
                }
                break;
            }
          }}
        >
          {({ submitForm, isSubmitting, isValid, status, values }) => {
            return (
              <Form>
                <p className="forma-aplikimit-inputet-labels">
                  Adresa e-mail *
                </p>
                <FastField name="email">
                  {(props) => (
                    <TextField
                      {...props}
                      variant="outlined"
                      placeholder="adresa@domain.com"
                      fullWidth
                      disabled={values.step > 1}
                    />
                  )}
                </FastField>
                {values.step > 1 && (
                  <>
                    <p className="forma-aplikimit-inputet-labels">Kodi *</p>
                    <FastField name="otpCode">
                      {(props) => (
                        <TextField
                          {...props}
                          variant="outlined"
                          placeholder="12345"
                          fullWidth
                        />
                      )}
                    </FastField>
                    <p className="forma-aplikimit-inputet-labels">
                      Vendosni fjalëkalimin e ri *
                    </p>
                    <FastField name="newPassword">
                      {(props) => (
                        <TextField
                          {...props}
                          type="password"
                          variant="outlined"
                          placeholder="Plotësoni fjalëkalimin"
                          fullWidth
                        />
                      )}
                    </FastField>
                    <p className="forma-aplikimit-inputet-labels">
                      Përsërit fjalëkalimin e ri *
                    </p>
                    <FastField name="confirmNewPassword">
                      {(props) => (
                        <TextField
                          {...props}
                          type="password"
                          variant="outlined"
                          placeholder="Përsërisni fjalëkalimin"
                          fullWidth
                        />
                      )}
                    </FastField>
                  </>
                )}

                <Grid justify="center" container style={{ marginTop: "64px" }}>
                  <Button
                    disableElevation
                    disableRipple
                    disableFocusRipple
                    variant="contained"
                    size="large"
                    style={{
                      backgroundColor:
                        !isValid || isSubmitting ? "" : "#CF0429",
                      color: !isValid || isSubmitting ? "" : "white",
                      textTransform: "none",
                      minWidth: "300px",
                      maxWidth: "300px",
                      minHeight: "43px",
                    }}
                    disabled={!isValid || isSubmitting}
                    onClick={submitForm}
                  >
                    {isSubmitting ? (
                      <CircularProgress
                        style={{
                          color: "#545454",
                          width: "24px",
                          height: "24px",
                        }}
                      />
                    ) : values.step === 1 ? (
                      "Dërgo kodin"
                    ) : values.step === 2 ? (
                      "Ndrysho fjalëkalimin"
                    ) : (
                      ""
                    )}
                  </Button>
                  <p>{status?.message}</p>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};
