import { useEffect, useRef, useState } from "react";

// material ui components
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  MenuItem,
  Typography,
  Toolbar,
  AppBar,
  ClickAwayListener,
  Grow,
  MenuList,
  Paper,
  Popper,
} from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";

import { useUserStore } from "../context/user";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  title: {
    fontSize: "1.1rem",
    cursor: "pointer",
  },
  userButton: {
    textTransform: "none",
    color: "#fff",
  },
}));

export const MenuBar = () => {
  const classes = useStyles();

  const { logOut } = useUserStore();

  const handleLogout = () => {
    setOpen(false);
    logOut();
  };

  const history = useHistory();
  // go back to komunikimet
  const goHome = () => {
    history.push("/kontributet");
  };

  // toolbar logic
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = async () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const { emri, mbiemri } = useUserStore();

  const renderMenu = (
    <Popper
      style={{ zIndex: "2" }}
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom",
          }}
        >
          <Paper elevation={2}>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList autoFocusItem={open} id="menu-list-grow">
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );

  return (
    <div className={classes.grow}>
      <AppBar style={{ backgroundColor: "#181A18" }} position="static">
        <Toolbar
          style={{ minHeight: "48px", paddingLeft: "5vw", paddingRight: "5vw" }}
        >
          <Typography noWrap onClick={goHome} className={classes.title}>
            Gati per Shqiperine
          </Typography>
          <div className={classes.grow} />
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography noWrap>
              {emri} {mbiemri}
            </Typography>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              ref={anchorRef}
              onClick={handleToggle}
              color="inherit"
              style={{ padding: 5 }}
            >
              <AccountCircle />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </div>
  );
};
