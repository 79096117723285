import { constants } from "../utils/constants";

export const fileUpload = async ({
  fileName,
  fileValue,
  token,
  kontributId,
  komunikimId,
}) => {
  const kon = kontributId ? { kontribut_id: kontributId } : {};
  const kom = komunikimId ? { komunikim_id: komunikimId } : {};
  try {
    const formData = new FormData();
    formData.append(fileName, fileValue);
    const resp = await fetch(`${constants.funcUrl}/api/uploads`, {
      method: "POST",
      body: formData,
      mode: "cors",
      headers: {
        token: token,
        ...kon,
        ...kom,
      },
    });
    const body = await resp.json();
    if (resp.ok) {
      // console.log(body);
      return { ok: true, id: body?.id };
    } else {
      return { ok: false, id: null };
    }
  } catch (e) {
    console.log({ e });
    return { ok: false, id: null };
  }
};
