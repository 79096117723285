import { Link, Typography } from "@material-ui/core";

export const Footer = () => {
  return (
    <div style={{ position: "relative" }}>
      <Typography
        variant="body2"
        color="textSecondary"
        align="center"
        style={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          // backgroundColor: "#eeeeee",
          // borderTopLeftRadius: "10px",
          // borderTopRightRadius: "10px",
          paddingTop: 5,
          paddingBottom: 5,
          // fontWeight: 700,
        }}
      >
        © {new Date().getFullYear()} | Mundësuar nga{" "}
        <Link color="inherit" href="https://akshi.gov.al/">
          Agjencia Kombëtare e Shoqërisë së Informacionit
        </Link>
        {/* {"."} */}
      </Typography>
    </div>
  );
};
