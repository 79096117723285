import * as yup from "yup";
import { constants } from "../utils/constants";

export const validation = {
  formularAplikimi: yup.object({
    gjinia: yup
      .mixed()
      .test(
        "asnjeFusheGjinia",
        constants.requiredErrorMessage,
        async (value) => {
          return value !== "empty";
        }
      ),
    nrTelefoni: yup.string().required(constants.requiredErrorMessage),
    shteti: yup.number().positive(constants.requiredErrorMessage),
    nivelEdukimi: yup
      .mixed()
      .test(
        "asnjeFusheGjinia",
        constants.requiredErrorMessage,
        async (value) => {
          return value !== "empty";
        }
      ),
    detajeEdukimiBachelor: yup
      .object()
      .shape({
        fushaStudimit: yup.string().optional(),
        universiteti: yup.string().optional(),
        shteti: yup.mixed().optional(),
      })
      .when("nivelEdukimi", {
        is: (value) => value.id > 0,
        then: yup.object().shape({
          fushaStudimit: yup.string().required(constants.requiredErrorMessage),
          universiteti: yup.string().required(constants.requiredErrorMessage),
          shteti: yup.number().positive(constants.requiredErrorMessage),
        }),
      }),
    detajeEdukimiMaster: yup
      .object()
      .shape({
        fushaStudimit: yup.string().optional(),
        universiteti: yup.string().optional(),
        shteti: yup.mixed().optional(),
      })
      .when("nivelEdukimi", {
        is: (value) => value.id > 1,
        then: yup.object().shape({
          fushaStudimit: yup.string().required(constants.requiredErrorMessage),
          universiteti: yup.string().required(constants.requiredErrorMessage),
          shteti: yup.number().positive(constants.requiredErrorMessage),
        }),
      }),
    detajeEdukimiDoktorature: yup
      .object()
      .shape({
        fushaStudimit: yup.string().optional(),
        universiteti: yup.string().optional(),
        shteti: yup.mixed().optional(),
      })
      .when("nivelEdukimi", {
        is: (value) => value.id > 3,
        then: yup.object().shape({
          fushaStudimit: yup.string().required(constants.requiredErrorMessage),
          universiteti: yup.string().required(constants.requiredErrorMessage),
          shteti: yup.number().positive(constants.requiredErrorMessage),
        }),
      }),
    profesioni: yup.string().required(constants.requiredErrorMessage),
    viteEksperience: yup
      .mixed()
      .test(
        "asnjeFusheViteEksperience",
        constants.requiredErrorMessage,
        async (value) => {
          return value !== "empty";
        }
      ),
    kthimi: yup.mixed().when("shteti", {
      is: (val) => {
        return val !== 74;
      },
      then: yup
        .mixed()
        .test(
          "asnjeFusheKthimi",
          constants.requiredErrorMessage,
          async (value) => {
            return value !== "empty";
          }
        ),
    }),
    menyraAngazhimit: yup
      .mixed()
      .test(
        "asnjeFusheKthimi",
        constants.requiredErrorMessage,
        async (value, context) => {
          const nonSense =
            context?.parent?.kthimi?.id === 1 && value?.id === 1 ? false : true;
          return value !== "empty" && nonSense;
        }
      ),
    // fushatEInteresit: yup
    //   .string()
    //   .required(constants.requiredErrorMessage),
    fushatEZgjedhura: yup
      .mixed()
      .test("asnjeFushe", constants.requiredErrorMessage, async (value) => {
        return value.length > 0;
      })
      .test(
        "shumeFusha",
        `Nuk mund të zgjidhni më shumë se ${constants.nrMaxIFushaveTeInteresit} fusha.`,
        async (value) => {
          return value.length < constants.nrMaxIFushaveTeInteresit + 1;
        }
      ),

    leterMotivimi: yup
      .mixed()
      .required(constants.requiredErrorMessage)
      .test(
        "fileSize",
        "Dokumenti është shumë i madh për tu dërguar.",
        async (value) => {
          return value !== null && value?.size <= constants.maxUploadSize;
        }
      )
      .test(
        "fileFormat",
        "Skedari nuk është në një prej formateve të lejuara.",
        async (value) => {
          return constants.supportedFileFormats.includes(value?.type);
        }
      ),
    leterMotivimi2: yup
      .mixed()
      .required(constants.requiredErrorMessage)
      .test(
        "fileSize",
        "Dokumenti është shumë i madh për tu dërguar.",
        async (value) => {
          return value !== null && value?.size <= constants.maxUploadSize;
        }
      )
      .test(
        "fileFormat",
        "Skedari nuk është në një prej formateve të lejuara.",
        async (value) => {
          return constants.supportedFileFormats.includes(value?.type);
        }
      ),
  }),
  regjistrimi: yup.object({
    emri: yup.string().required(constants.requiredErrorMessage),
    mbiemri: yup.string().required(constants.requiredErrorMessage),
    email: yup
      .string()
      .email(constants.emailWrongFormatErrorMessage)
      .required(constants.requiredErrorMessage),
    password: yup
      .string()
      .required(constants.requiredErrorMessage)
      .min(8, "")
      .matches(
        /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9]).*$/,
        constants.passWrongFormatErrorMessage
      ),
    confirmPassword: yup
      .string()
      .required(constants.requiredErrorMessage)
      .oneOf([yup.ref("password"), null], constants.notSamePassErrorMessage),
  }),
  logimi: yup.object({
    email: yup
      .string()
      .email(constants.emailWrongFormatErrorMessage)
      .required(constants.requiredErrorMessage),
    password: yup.string().required(constants.requiredErrorMessage),
  }),
  confirmRegister: yup.object({
    code: yup
      .string()
      .required(constants.requiredErrorMessage)
      .min(5, constants.codeShortErrorMessage),
  }),
  komunikimIRi: yup.object({
    permbajtja: yup.string().required(constants.requiredErrorMessage),
    files: yup
      .mixed()
      .test("fileSize", constants.dokumentTooBigErrorMessage, async (value) => {
        if (value.length === 0) {
          return true;
        } else {
          const validations = value.map((v) => {
            return v.size <= constants.maxUploadSize;
          });
          return !validations.includes(false);
        }
      })
      .test(
        "fileFormat",
        constants.dokumentWrongFormatErrorMessage,
        async (value) => {
          if (value.length === 0) {
            return true;
          } else {
            const validations = value.map((v) => {
              return constants.supportedFileFormats.includes(v.type);
            });
            return !validations.includes(false);
          }
        }
      ),
  }),
  resetPassword: yup.object({
    email: yup
      .string()
      .email(constants.emailWrongFormatErrorMessage)
      .required(constants.requiredErrorMessage),
    otpCode: yup.string().when("step", {
      is: (value) => value > 1,
      then: yup
        .string()
        .required(constants.requiredErrorMessage)
        .min(5, constants.codeShortErrorMessage),
    }),
    newPassword: yup.string().when("step", {
      is: (val) => val > 1,
      then: yup
        .string()
        .required(constants.requiredErrorMessage)
        .min(8, constants.shortPassErrorMessage)
        .matches(
          /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9]).*$/,
          constants.passWrongFormatErrorMessage
        ),
    }),
    confirmNewPassword: yup.string().when("step", {
      is: (val) => val > 1,
      then: yup
        .string()
        .required(constants.requiredErrorMessage)
        .oneOf(
          [yup.ref("newPassword"), null],
          constants.notSamePassErrorMessage
        ),
    }),
  }),
};
